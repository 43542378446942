import React from 'react'
import { Toolbar } from '@mui/material'
import Dashboard from '../organisms/Dashboard'

export default function HomePage() {
  return (
    <div className="content-container">
      <Toolbar />
      <Dashboard />
    </div>
  )
}
