import React, { useCallback, useEffect, useState } from 'react'
import T from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import config from '../../../config'
import request from '../../../lib/request'
import AjaxFileUpload from '../../../lib/ajax-file-upload'
import DropAreaWidget, {
  STATUS_UPLOADING,
  STATUS_WAIT_DROP,
  STATUS_COMPLETE,
  STATUS_ERROR,
} from '../DropAreaWidget'

const useStyles = makeStyles({
  title: {
    '& h2': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  paper: {
    maxWidth: 'none',
    width: '700px',
  },
})

export default function UploadDialog({ options }) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [droppedImage, setDroppedImage] = useState(null)
  const [dropStatus, setDropStatus] = useState(STATUS_WAIT_DROP)
  const [errorText, setErrorText] = useState('')
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (options.open) {
      setDroppedImage(null)
      setDropStatus(STATUS_WAIT_DROP)
      setProgress(0)
    }
  }, [options.open])

  useEffect(() => {
    if (droppedImage) {
      setDropStatus(STATUS_COMPLETE)
    }
  }, [droppedImage])

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      setErrorText(rejectedFiles[0].errors[0].message)
      setDropStatus(STATUS_ERROR)
    }
    if (acceptedFiles.length > 0) {
      setDropStatus(STATUS_UPLOADING)
      setProgress(0)
    }
    acceptedFiles.forEach((file, index) => {
      if (index > 0) return
      const reader = new FileReader()

      if (file.size > 100) {
      }

      setDroppedImage(null)

      reader.onabort = () => console.info('file reading was aborted') // eslint-disable-line
      reader.onerror = () => console.error('file reading has failed') // eslint-disable-line
      reader.onload = async () => {
        setDroppedImage(file)
      }
      reader.readAsDataURL(file)
    })
  }, [])

  const onSave = async () => {
    const initiateResultFull = await request.post('/upload-initiator', {
      contentType: droppedImage.type,
      suffix: options.relativePath,
    })

    droppedImage.uuid = initiateResultFull.fileId
    droppedImage.extension = droppedImage.name.substr(
      droppedImage.name.lastIndexOf('.') + 1
    )
    droppedImage.newFileName = `${droppedImage.uuid}.${droppedImage.extension}`

    const uploader = new AjaxFileUpload(
      initiateResultFull.s3PutObjectUrl,
      droppedImage,
      {
        contentType: droppedImage.type,
      }
    )

    const onComplete = (file) => {
      options.handleSave(file)
    }
    const onError = (file) => {
      console.error('ERROR', file) // eslint-disable-line
      setErrorText(t('uploadDialog.errorText'))
      setDropStatus(STATUS_ERROR)
    }
    const onAbort = () => {
      console.info('onAbort') // eslint-disable-line
    }
    const onProgress = (event, progress) => {
      setProgress(progress)
    }
    uploader.on('error', onError)
    uploader.on('complete', onComplete)
    uploader.on('abort', onAbort)
    uploader.on('progress', onProgress)
    uploader.start()
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    validator: (file) => {
      if (file.size > config.upload.maxFileSize) {
        return {
          code: 'too-large',
          message: t('uploadDialog.tooLarge'),
        }
      }
      return null
    },
  })

  return (
    <Dialog
      open={options.open}
      onClose={options.handleClose}
      classes={{ paper: classes.paper }}
      maxWidth={false}
    >
      <DialogTitle className={classes.title}>
        {t('uploadDialog.title')}
      </DialogTitle>
      <DialogContent>
        {droppedImage && (
          <div>
            <img
              src={URL.createObjectURL(droppedImage)}
              alt=""
              style={{ maxWidth: '300px' }}
            />
          </div>
        )}
        <DropAreaWidget
          status={dropStatus}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          dropText={t('uploadDialog.dropText')}
          uploadText={t('uploadDialog.uploadText')}
          cancelText={t('uploadDialog.cancelText')}
          errorText={errorText}
          errorButtonText={t('uploadDialog.errorButtonText')}
          onErrorAck={() => setDropStatus(STATUS_WAIT_DROP)}
          progress={progress}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={options.handleClose}
          color="secondary"
          aria-label={t('common.cancel')}
        >
          {t('common.cancel')}
        </Button>
        <Button
          onClick={onSave}
          disabled={!droppedImage}
          color="primary"
          aria-label={t('common.save')}
        >
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

UploadDialog.propTypes = {
  options: T.shape({
    open: T.bool.isRequired,
    handleClose: T.func.isRequired,
    handleSave: T.func.isRequired,
    relativePath: T.string.isRequired,
  }),
}
