import React from 'react'
import { useTheme } from '@aws-amplify/ui-react'
import { Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function AuthLoginHeader() {
  const { t } = useTranslation()
  const { tokens } = useTheme()

  return (
    <Box
      textAlign="center"
      padding={`${tokens.space.xl} ${tokens.space.xl} 0 ${tokens.space.xl}`}
    >
      <Typography variant="h5">{t('auth.loginMessage')}</Typography>
    </Box>
  )
}
