import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Spinner from '../atoms/Spinner'
import TeamForm from '../organisms/TeamForm' // Updated component
import useSnackbar from '../organisms/Snackbar/useSnackbar'
import TeamMembers from '../organisms/TeamMembers'
import useFetchChallenge from '../../hooks/useFetchChallenge'

export default function TeamDetail() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { challengeId, id, companyId } = useParams() // Updated parameter name
  const { fetchedObject: challenge, fetchError } =
    useFetchChallenge(challengeId)

  const isNew = !id

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew
            ? 'teamDetailPage.newTitle' // Updated translation key
            : 'teamDetailPage.detailTitle' // Updated translation key
        )}
      </Typography>
      {challenge ? (
        <>
          <Typography variant="h4">{challenge?.name}</Typography>
          <TeamForm
            challengeId={challengeId}
            teamId={id}
            companyId={companyId}
          />
          <Divider sx={{ m: 3 }} />

          {id && ( // Updated condition
            <TeamMembers
              challengeId={challengeId}
              teamId={id}
              companyId={companyId}
            />
          )}
        </>
      ) : (
        <Spinner show={true} />
      )}
    </div>
  )
}
