import theme from './theme'

const amplifyTheme = {
  name: 'my-amplify-theme',
  tokens: {
    components: {
      fieldControl: {
        focus: {
          borderColor: { value: theme.palette.primary.main },
        },
      },
      button: {
        focus: {
          borderColor: { value: theme.palette.primary.main },
          backgroundColor: { value: theme.palette.common.white },
          color: { value: theme.palette.common.black },
        },
        hover: {
          borderColor: { value: theme.palette.primary.main },
          backgroundColor: { value: theme.palette.common.white },
          color: { value: theme.palette.common.black },
        },
        active: {
          borderColor: { value: theme.palette.primary.main },
          backgroundColor: { value: theme.palette.common.white },
          color: { value: theme.palette.common.black },
        },
        primary: {
          active: {
            backgroundColor: { value: theme.palette.primary.light },
          },
          focus: {
            backgroundColor: { value: theme.palette.primary.light },
          },
          hover: {
            backgroundColor: { value: theme.palette.primary.light },
          },
          backgroundColor: { value: theme.palette.primary.main },
        },
      },
    },
  },
}

export default amplifyTheme
