import React from 'react'
import T from 'prop-types'
import { Box, Typography } from '@mui/material'
import InfoPopover from './InfoPopover'

export default function KPI({ name, value, color, info }) {
  return (
    <Box bgcolor={color} width={180} height={180} borderRadius="10px" mb={2}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height={1}
        p={2}
      >
        <Box width={1} display="flex" justifyContent="flex-end" height={24}>
          {!!info && <InfoPopover>{info}</InfoPopover>}
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          flexBasis="100%"
        >
          <Typography
            variant={value.toString().length > 6 ? 'h4' : 'h3'}
            component="p"
            align="center"
          >
            {value}
          </Typography>
          <Typography variant="body2" align="center">
            {name}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

KPI.propTypes = {
  name: T.string,
  value: T.oneOfType([T.string, T.number]),
  color: T.string,
  info: T.string,
}
