import axios from 'axios'
import { Auth } from 'aws-amplify'
import fileDownload from 'js-file-download'

import config from '../config'

const buildUrl = (url) => config.server.baseUrl + url

const addAuthHeaders = async (options) => {
  try {
    const user = await Auth.currentSession()
    options = {
      ...options,
      headers: {
        Authorization: `Bearer ${user.idToken.jwtToken}`,
      },
    }
  } catch (e) {
    // ignore if user is not authenticated
  }
  return options
}

/**
 * Methods for wrapping the requests methods of the `axios` module.
 */
async function get(url, options) {
  options = await addAuthHeaders(options)
  const result = await axios.get(buildUrl(url), options)
  return adaptResult(result)
}

async function downloadFile(url, fileName, options) {
  options = {
    ...(await addAuthHeaders(options)),
    responseType: 'blob',
  }
  // To force the Accept header, axios requires a new instance!
  // This header is required by API Gateway in order to return a binary media type (and not a base64 string).
  const instance = axios.create({
    headers: {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
  })
  const res = await instance.get(buildUrl(url), options)
  fileDownload(res.data, fileName)
}

async function post(url, data, options) {
  options = await addAuthHeaders(options)
  const result = await axios.post(buildUrl(url), data, options)
  return adaptResult(result)
}

async function put(url, data, options) {
  options = await addAuthHeaders(options)
  const result = await axios.put(buildUrl(url), data, options)
  return adaptResult(result)
}

async function patch(url, data, options) {
  options = await addAuthHeaders(options)
  const result = await axios.patch(buildUrl(url), data, options)
  return adaptResult(result)
}

async function _delete(url, options) {
  options = await addAuthHeaders(options)
  const result = await axios.delete(buildUrl(url), options)
  return adaptResult(result)
}

const adaptResult = (result) => {
  return result.data.body
}

const request = {
  get,
  downloadFile,
  post,
  put,
  patch,
  delete: _delete,
}

export default request
