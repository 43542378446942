import React, { useCallback, useState } from 'react'
import { Auth } from 'aws-amplify'

import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import request from '../../lib/request'

export default function SsoEmail() {
  const { t } = useTranslation()
  const history = useHistory()
  const [email, setEmail] = useState('')
  const [noIdentityProvider, setNoIdentityProvider] = useState(false)

  const emailChangeHandler = useCallback((e) => {
    setEmail(e.target.value)
  }, [])

  const submitHandler = useCallback(
    async (e) => {
      e.preventDefault()
      setNoIdentityProvider(false)
      try {
        const { identityProviderName } = await request.get(
          `/sso/check-email?email=${encodeURIComponent(email)}`
        )
        if (identityProviderName) {
          await Auth.federatedSignIn({ customProvider: identityProviderName })
        } else {
          setNoIdentityProvider(true)
        }
      } catch (e) {
        setNoIdentityProvider(true)
      }
    },
    [email]
  )

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('ssoEmailPage.title')}</Typography>

      <Typography>{t('ssoEmailPage.body')}</Typography>

      <form onSubmit={submitHandler}>
        <input
          type="email"
          name="email"
          value={email}
          onChange={emailChangeHandler}
          style={{ width: '100%' }}
        />

        <Typography>
          <Button type="submit" color="primary">
            {t('ssoEmailPage.buttonLabel')}
          </Button>
        </Typography>
      </form>

      {noIdentityProvider && (
        <Typography color="error">
          {t('ssoEmailPage.noIdentityProvider')}
        </Typography>
      )}

      <Button
        onClick={() => history.goBack()}
        color="secondary"
        aria-label={t('common.back')}
      >
        {t('common.back')}
      </Button>
    </div>
  )
}
