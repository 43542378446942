import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchHeadquarters({ companyId }) {
  const get = useCallback(async () => {
    return request.get(`/admin/companies/${companyId}/headquarters`)
  }, [companyId])
  return useFetch(get)
}
