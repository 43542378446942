import React from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MessageForm from '../organisms/MessageForm'

export default function MessageDetail() {
  const { t } = useTranslation()
  const { id } = useParams()
  const isNew = !id

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew ? 'messageDetailPage.newTitle' : 'messageDetailPage.detailTitle'
        )}
      </Typography>
      <MessageForm messageId={id} />
    </div>
  )
}
