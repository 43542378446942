import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Divider,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import useConfig from '../../hooks/useConfig'
import useFetchBackOfficeUsersByRoles from '../../hooks/useFetchBackOfficeUsersByRoles'

import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import TabPanel from '../atoms/TabPanel'
import BackOfficeUsersTab from '../organisms/BackOfficeUsersTab'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

export default function BackOfficeUsers() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId, role } = useParams()
  const history = useHistory()
  const { notAdminRoles } = useConfig()

  const {
    fetchedObject: company,
    isFetching,
    fetchError,
  } = useFetchCompany(companyId)

  const {
    fetchedObject: backOfficeUsersByRoles,
    isFetchingBOUsers,
    fetchErrorBOUsers,
  } = useFetchBackOfficeUsersByRoles(companyId)

  const [tabIndex, setTabIndex] = useState(
    role ? notAdminRoles.indexOf(role) : 0
  )

  if (!role) {
    window.history.replaceState(
      null,
      null,
      `/company/${companyId}/back-office-users/${notAdminRoles[0]}`
    )
  }

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue)
    window.history.replaceState(
      null,
      null,
      `/company/${companyId}/back-office-users/${notAdminRoles[newValue]}`
    )
  }

  useEffect(() => {
    if (fetchError || fetchErrorBOUsers) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${
          fetchError || fetchErrorBOUsers
        }`,
      })
    }
  }, [fetchError, fetchErrorBOUsers, showSnackbarMessage, t])

  const backOfficeUsers = Object.keys(backOfficeUsersByRoles || {})
    .flatMap((role) => backOfficeUsersByRoles[role])
    .map((u) => `${u.firstName} ${u.lastName}`)

  return isFetching || isFetchingBOUsers ? (
    <Spinner show={true} />
  ) : (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('backOfficeUsersPage.title')}</Typography>
      <Typography variant="h4">{company?.name}</Typography>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {t('backOfficeUsersPage.backOfficeAccess.title')}
          </Typography>
          {backOfficeUsers && (
            <Typography>
              {backOfficeUsers.length
                ? t('backOfficeUsersPage.backOfficeAccess.usersList') +
                  backOfficeUsers.join(', ')
                : t('backOfficeUsersPage.backOfficeAccess.noUsers')}
            </Typography>
          )}
        </CardContent>
      </Card>

      <Divider
        style={{
          margin: '10px 0 20px',
          height: '3px',
          backgroundColor: 'rgba(0, 0, 0, 0.40)',
        }}
      />

      <Paper>
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          aria-label="simple tabs example"
        >
          {notAdminRoles.map((role) => (
            <Tab key={role} label={t(`roles.${role}`)} />
          ))}
        </Tabs>
      </Paper>
      {notAdminRoles.map((role, index) => (
        <TabPanel key={role} value={tabIndex} index={index}>
          <BackOfficeUsersTab
            company={company}
            role={role}
            backOfficeUsersByRoles={backOfficeUsersByRoles}
          />
        </TabPanel>
      ))}
      <Button
        onClick={() => history.push(`/company/${companyId}`)}
        color="secondary"
        aria-label={t('common.back')}
      >
        {t('common.back')}
      </Button>
    </div>
  )
}
