import T from 'prop-types'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button } from '@mui/material'

import request from '../../../lib/request'
import useSnackbar from '../Snackbar/useSnackbar'
import AutocompleteTextField from '../../atoms/AutocompleteTextField'

export default function NewMember({
  challengeId,
  teamId,
  companyId,
  onNewMemberAdded,
}) {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const [user, setUser] = useState(null)

  const handleAdd = async () => {
    if (!user?.id) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t('teamMembersPage.missingUserError'),
      })
      return
    }
    try {
      await request.post(`/admin/challenge-team-members`, {
        challengeId: challengeId,
        teamId: teamId,
        userId: user.id,
      })
      showSnackbarMessage(t('common.memberAdded'))
      onNewMemberAdded()
    } catch (error) {
      if (error.response?.data?.errorCode === 'alreadySubscribed') {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: t('teamMembersPage.alreadySubscribed', {
            teamName: error.response.data.teamName,
          }),
        })
      } else {
        console.error(error) // eslint-disable-line
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: error.message,
        })
      }
    }
  }

  return (
    <Box>
      Per aggiungiungere un membro, inserisci l'email qui sotto e premi
      Aggiungi:
      <Box>
        <AutocompleteTextField
          name="email"
          value={user}
          onChange={(event, newValue) => {
            setUser(newValue)
          }}
          label={t('entities.backOfficeUser.user')}
          partialUrl={`/admin/users/autocomplete?companyId=${companyId}&search=`}
          getOptionLabel={(option) => {
            if (!option?.email) {
              return ''
            }
            return option?.email
              ? `${option.lastName} ${option.firstName} (${option.email})`
              : ''
          }}
          getOptionSelected={(option, value) => option.email === value.email}
        />

        <Button
          onClick={handleAdd}
          color="primary"
          aria-label={t('common.add')}
        >
          {t('common.add')}
        </Button>
      </Box>
    </Box>
  )
}

NewMember.propTypes = {
  challengeId: T.string.isRequired,
  teamId: T.string.isRequired,
  companyId: T.string.isRequired,
  onNewMemberAdded: T.func.isRequired,
}
