import React from 'react'
import T from 'prop-types'
import { Box } from '@mui/material'
import KPI from './KPI'

export default function KPIChart({
  data = [],
  colors = [
    '#FBF0B8',
    '#C5E8EA',
    '#FAD4C0',
    '#FEE9E1',
    '#E9DDFA',
    '#E6F7BB',
    '#F8D6E1',
  ],
}) {
  return (
    <Box display="flex" justifyContent="space-evenly" flexWrap="wrap">
      {data.map((kpi, index) => (
        <KPI
          name={kpi?.name}
          value={kpi?.value}
          info={kpi?.info}
          key={`kpi-${index}`}
          color={colors[index % colors.length]}
        />
      ))}
    </Box>
  )
}

KPIChart.propTypes = {
  data: T.array,
  colors: T.array,
}
