import { useEffect, useState } from 'react'
import useGoogleMap from './useGoogleMap'

const useGooglePlaces = ({ searchAddress, googleMapsKey, mapElementId }) => {
  const { map, googleApiLoaded } = useGoogleMap({ googleMapsKey, mapElementId })
  const [placesService, setPlacesService] = useState(null)
  const [place, setPlace] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    if (!googleApiLoaded || !map) {
      return
    }

    setPlacesService(new window.google.maps.places.PlacesService(map))
  }, [googleApiLoaded, map])

  useEffect(() => {
    if (!searchAddress || !placesService) {
      return
    }

    const request = {
      query: searchAddress,
      fields: ['name', 'formatted_address', 'geometry', 'place_id'],
    }

    placesService.findPlaceFromQuery(request, (results, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        setError(status)
        return
      }
      if (!results || !results.length) {
        setError('ZERO_RESULTS')
        return
      }

      const place = results[0]
      const detailRequest = {
        placeId: place.place_id,
        fields: ['name', 'address_components'],
      }

      const detailCallback = (detail, status) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          setError(status)
          return
        }
        setError(null)
        setPlace({ ...place, ...detail })
      }

      placesService.getDetails(detailRequest, detailCallback)
    })
  }, [searchAddress, map, placesService])

  return { map, place, error }
}

export default useGooglePlaces
