import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Typography,
  ListItemText,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  List,
  Paper,
  Stack,
} from '@mui/material'
import { makeStyles } from '@mui/styles'

import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import useFetch from '../../../hooks/useFetch'

import request from '../../../lib/request'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'

import Checkbox from '../../atoms/Checkbox'
import AutocompleteTextField from '../../atoms/AutocompleteTextField'
import useFetchHeadquarters from '../../../hooks/useFetchHeadquarters'

import { BackOfficeUserSchema } from './validators'

const useStyles = makeStyles((theme) => ({
  menuHeadquarters: {
    height: 400,
  },
}))

export default function BackOfficeUserForm({ companyId, role, userId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const classes = useStyles()

  const headquarters = useFetchHeadquarters({ companyId })

  const { isAdmin } = useAuth()

  const isNew = !userId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const fetchBackOfficeUser = useCallback(async () => {
    if (!userId) {
      return null
    }
    return request.get(
      `/admin/back-office-users/${userId}?companyId=${companyId}&role=${role}`
    )
  }, [userId, companyId, role])

  const {
    fetchedObject: backOfficeUser,
    isFetching,
    fetchError,
  } = useFetch(fetchBackOfficeUser)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      if (isNew) {
        const data = {
          userId: values.user.id,
          companyId,
          role,
          subscribedToReport: values.subscribedToReport,
          headquarterIds: values.headquarterIds.map((head) => head.id),
          allHeadquarters: values.allHeadquarters,
        }
        await request.post(`/admin/back-office-users`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/back-office-users/${role}`)
      } else {
        const data = {
          companyId,
          role,
          subscribedToReport: values.subscribedToReport,
          headquarterIds: values.allHeadquarters
            ? []
            : values.headquarterIds.map((head) => head.id),
          allHeadquarters: values.allHeadquarters,
        }
        await request.patch(`/admin/back-office-users/${userId}`, data)
        actions.setSubmitting(false)
        setPageMode('view')
        history.push(`/company/${companyId}/back-office-users/${role}`)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.response?.data?.errorCode
          ? t(`backOfficeUserDetailPage.errors.${e.response.data.errorCode}`)
          : e.message,
      })
      actions.setSubmitting(false)
    }
  }

  const handleDelete = (e) => {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t(
        'backOfficeUsersPage.deleteConfirmDescription',
        backOfficeUser
      ),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(
            `/admin/back-office-users/${userId}?companyId=${companyId}&role=${role}`
          )
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/back-office-users/${role}`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: `${t('common.unexpectedError')} ${e.message}`,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0} mt={2}>
      <Formik
        innerRef={formikRef}
        validationSchema={BackOfficeUserSchema(t, isNew)}
        initialValues={{
          allHeadquarters: isNew ? true : backOfficeUser?.allHeadquarters,
          headquarterIds: [...(headquarters?.fetchedObject || [])].filter(
            (headerquarter) =>
              backOfficeUser?.headquarterIds.indexOf(headerquarter.id) > -1
          ),
          head: [],
          allowExternalCompany: false,
          user: null,
          subscribedToReport: backOfficeUser?.subscribedToReport || false,
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting, values, setFieldValue }) => (
          <Form>
            {isNew ? (
              <>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox name="allowExternalCompany" color="primary" />
                    }
                    label={t('backOfficeUserDetailPage.allowExternalCompany')}
                    style={{ margin: 0 }}
                  />
                  {values.allowExternalCompany && (
                    <Typography color="error">
                      {t('backOfficeUserDetailPage.externalCompanyDisclaimer')}
                    </Typography>
                  )}
                </Box>
                <AutocompleteTextField
                  name="user"
                  value={values.user}
                  onChange={(event, newValue) => {
                    setFieldValue('user', newValue)
                  }}
                  label={t('entities.backOfficeUser.user')}
                  partialUrl={`/admin/users/autocomplete?companyId=${
                    values.allowExternalCompany ? '' : companyId
                  }&search=`}
                  getOptionLabel={(option) => {
                    if (!option?.email) {
                      return ''
                    }
                    const companyName = values.allowExternalCompany
                      ? ` [${option.companyName}]`
                      : ''
                    return option?.email
                      ? `${option.lastName} ${option.firstName} (${option.email})${companyName}`
                      : ''
                  }}
                  getOptionSelected={(option, value) =>
                    option.email === value.email
                  }
                />
              </>
            ) : (
              backOfficeUser && (
                <Box mb="5px">
                  <Typography variant="h6">
                    {`${backOfficeUser.firstName} ${backOfficeUser.lastName} (${backOfficeUser.email})`}
                  </Typography>
                  {backOfficeUser.userCompanyId !== companyId && (
                    <>
                      <span>
                        {t('backOfficeUserDetailPage.userCompanyName')}:{' '}
                      </span>
                      <span>{backOfficeUser.userCompanyName}</span>
                    </>
                  )}
                </Box>
              )
            )}
            <Stack>
              <FormControlLabel
                disabled={readOnly}
                control={<Checkbox name="allHeadquarters" color="primary" />}
                label={t('backOfficeUserDetailPage.allHeadquarters')}
                style={{ margin: 0 }}
              />
              {!readOnly ? (
                <Box>
                  {!values.allHeadquarters && headquarters.fetchedObject && (
                    <FormControl fullWidth size="small">
                      <InputLabel id="headquarterId-label">
                        {t('backOfficeUserDetailPage.headquarters')}
                      </InputLabel>
                      <Select
                        MenuProps={{ className: classes.menuHeadquarters }}
                        labelId="headquarterId-label"
                        label={'Sedi'}
                        name="headquarterIds"
                        multiple
                        value={values.headquarterIds}
                        renderValue={(selected) => {
                          return selected
                            ? selected.map((head) => head.name).join(', ')
                            : t('common.select')
                        }}
                        disabled={!!readOnly}
                        onChange={({ target }) => {
                          const { value } = target
                          if (value[value.length - 1] === 'all') {
                            setFieldValue(
                              'headquarterIds',
                              values.headquarterIds.length ===
                                headquarters.fetchedObject.length
                                ? []
                                : headquarters.fetchedObject
                            )
                            return
                          }
                          setFieldValue('headquarterIds', value)
                        }}
                      >
                        <MenuItem value={'all'}>
                          <Checkbox
                            name="all"
                            checked={
                              values.headquarterIds.length ===
                              headquarters.fetchedObject.length
                            }
                          />
                          <ListItemText primary={t('common.selectAll')} />
                        </MenuItem>
                        {headquarters.fetchedObject.map((c) => {
                          return (
                            <MenuItem key={c.id} value={c}>
                              <Checkbox
                                name="subsidiary"
                                checked={
                                  !!values.headquarterIds.find(
                                    (subsidiary) => subsidiary.id === c.id
                                  )
                                }
                              />
                              <ListItemText primary={c.name} />
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  )}
                </Box>
              ) : (
                readOnly &&
                headquarters.fetchedObject &&
                !backOfficeUser.allHeadquarters && (
                  <>
                    <Paper
                      sx={{
                        maxWidth: 300,
                        padding: '0px 8px 0px 8px',
                        position: 'relative',
                        '&:after': {
                          content: '" "',
                          position: 'absolute',
                          bottom: 0,
                          background:
                            'linear-gradient(to bottom, transparent, white)',
                          height: '50px',
                          width: '100%',
                        },
                      }}
                    >
                      <Typography variant="h6">
                        {t('backOfficeUserDetailPage.headquarters')}
                      </Typography>
                      <List
                        className="headertersList"
                        sx={{
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300,
                          paddingTop: 0,
                          paddingBottom: '32px',
                          '& ul': { padding: 0 },
                        }}
                      >
                        {values.headquarterIds.map((headquarter) => (
                          <li key={headquarter.id}>{headquarter.name}</li>
                        ))}
                      </List>
                    </Paper>
                  </>
                )
              )}
              <FormControlLabel
                control={<Checkbox name="subscribedToReport" color="primary" />}
                label={t('backOfficeUserDetailPage.subscribedToReport')}
                style={{ margin: 0 }}
                disabled={readOnly}
              />
            </Stack>
            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() =>
                      history.push(
                        `/company/${companyId}/back-office-users/${role}`
                      )
                    }
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                      <Button
                        onClick={handleDelete}
                        color="primary"
                        aria-label={t('common.delete')}
                      >
                        {t('common.delete')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isNew ? (
                    <Button
                      onClick={() =>
                        history.push(
                          `/company/${companyId}/back-office-users/${role}`
                        )
                      }
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                  ) : (
                    <Button
                      type="reset"
                      color="secondary"
                      aria-label={t('common.cancel')}
                    >
                      {t('common.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

BackOfficeUserForm.propTypes = {
  companyId: T.string.isRequired,
  role: T.string.isRequired,
  userId: T.string,
}
