import { Box } from '@mui/material'
import T from 'prop-types'
import React from 'react'

export default function ControlBox({ children }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        border: '1px dotted gray',
        margin: '0 0 0 0.5rem',
        alignItems: 'center',
        paddingLeft: '1rem',
      }}
    >
      {children}
    </Box>
  )
}

ControlBox.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
}
