import { useEffect, useState } from 'react'

import request from '../lib/request'

export default function useCompaniesSectors() {
  const [companiesSectors, setCompaniesSectors] = useState([])

  useEffect(() => {
    const fetchCompaniesSectors = async () => {
      const data = await request.get(
        `/admin/reports/rides-by-type/companies-sectors`
      )
      setCompaniesSectors(data)
    }
    // noinspection JSIgnoredPromiseFromCall
    fetchCompaniesSectors()
  }, [])

  return companiesSectors
}
