import React from 'react'
import { Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import T from 'prop-types'

import theme from '../../theme'

const useStyles = makeStyles({
  action: {
    margin: theme.spacing(0, 1),
  },
})

export default function Action({ action, data }) {
  const classes = useStyles()
  const hidden =
    typeof action.hidden === 'function' ? action.hidden(data) : !!action.hidden

  const Component = action.render ? (
    action.render(data)
  ) : (
    <Button
      color="primary"
      className={classes.action}
      onClick={(e) => action.onClick(e, data)}
    >
      {action.text}
    </Button>
  )

  return hidden ? null : Component
}

Action.propTypes = {
  action: T.object,
  data: T.object,
}
