export const createMarker = (location, marker, map) => {
  if (marker.current) {
    marker.current.setMap(null)
  }
  marker.current = new window.google.maps.marker.AdvancedMarkerElement({
    map,
    position: location,
  })
}

const getAddressComponentByType = (components, type, shortFormat = false) => {
  const component = components.find((c) => c.types.includes(type))
  return component ? component[shortFormat ? 'short_name' : 'long_name'] : ''
}

export const fillFormValues = (formik, place) => {
  formik.setFieldValue('completeAddress', place.formatted_address)
  const route = getAddressComponentByType(place.address_components, 'route')
  const streetNumber = getAddressComponentByType(
    place.address_components,
    'street_number'
  )
  const addedStreetNumber = streetNumber ? `, ${streetNumber}` : ''
  formik.setFieldValue('address', `${route}${addedStreetNumber}`)
  const postalTown = getAddressComponentByType(
    place.address_components,
    'postal_town'
  )
  const locality = getAddressComponentByType(
    place.address_components,
    'locality'
  )
  const province = getAddressComponentByType(
    place.address_components,
    'administrative_area_level_2',
    true
  )
  const city = postalTown || `${locality} (${province})`
  formik.setFieldValue('city', city)
  formik.setFieldValue(
    'zipCode',
    getAddressComponentByType(place.address_components, 'postal_code')
  )
  formik.setFieldValue(
    'country',
    getAddressComponentByType(place.address_components, 'country')
  )
  formik.setFieldValue('lat', place.geometry.location.lat())
  formik.setFieldValue('lng', place.geometry.location.lng())
  formik.setFieldValue('placeId', place.place_id)
}
