import * as Yup from 'yup'

export const HeadquarterSchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')

  return Yup.object().shape({
    name: Yup.string().required(fieldRequired),
    isMain: Yup.boolean(),
    address: Yup.string(),
    zipCode: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    lat: Yup.number().typeError(invalidValue),
    lng: Yup.number().typeError(invalidValue),
    checkMaxMeters: Yup.number().typeError(invalidValue).integer(invalidValue),
    maxDriverExtraTravel: Yup.number().nullable(),
  })
}
