import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchCompany(companyId) {
  const fetchCompany = useCallback(async () => {
    if (!companyId) {
      return null
    }
    return request.get(`/admin/companies/${companyId}`)
  }, [companyId])

  return useFetch(fetchCompany)
}
