import EventEmitter from 'events'
import axios from 'axios'

export const UPLOAD_STATUS = {
  FAILED: -1,
  PENDING: 0,
  UPLOADING: 1,
  UPLOADED: 2,
}

const ONE_YEAR_IN_SECONDS = 31536000

export default class AjaxFileUpload extends EventEmitter {
  constructor(url, file, options = {}) {
    super()
    this.url = url
    this.file = file
    this.preview = file.preview || URL.createObjectURL(file)

    this.contentType = options.contentType
    this.onComplete = options.onComplete
    this.onAbort = options.onAbort
    this.onError = options.onError
    this.onProgress = options.onProgress

    this.getDimensions()
    this.getDimensions()
  }

  getDimensions() {
    // FIXME separate logic for RN and apply a condition based on mimetype (if not image this method is un-useful)
    const img = new Image()
    img.src = this.getPreview()
    img.onload = () => {
      this.file.width = img.naturalWidth
      this.file.height = img.naturalHeight
    }
  }

  getFile() {
    return this.file
  }

  getFileType() {
    return this.getFile().type
  }

  getPreview() {
    return this.preview
  }

  getFilename() {
    return this.getFile().name
  }

  isImage() {
    return /^image\//.exec(this.getFileType())
  }

  getResultS3Filename() {
    return this.result && this.result.filename
  }

  getPublicUrl() {
    return this.result && this.result.publicUrl
  }

  abortUpload() {
    if (this.upload) {
      this.upload.abortUpload()
      // this.onAbort && this.onAbort(this.file)
      this.emit('abort', this.file)
      this.upload = null
    }
  }

  markFailed() {
    this.failed = true
  }

  isFailed() {
    return !!this.failed
  }

  async start() {
    const formData = new FormData()
    formData.append('file', this.file, this.file.name)

    const requestOptions = {
      headers: {
        'content-type': this.contentType,
        'Cache-Control': `max-age=${ONE_YEAR_IN_SECONDS}`,
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        )
        // this.onProgress(this.file, percentCompleted, UPLOAD_STATUS.UPLOADING)
        this.emit(
          'progress',
          this.file,
          percentCompleted,
          UPLOAD_STATUS.UPLOADING
        )
      },
    }
    try {
      this.result = axios.put(this.url, this.file, requestOptions)
      this.emit('complete', this.file, this.preview)
    } catch (e) {
      this.emit('error', this.file)
      this.markFailed()
    }
  }
}
