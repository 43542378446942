import React from 'react'
import T from 'prop-types'

import { Card, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import BusinessIcon from '@mui/icons-material/Business'

import { formatRideTime } from '../../../lib/formatter'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(1),
    '& svg': {
      position: 'relative',
      top: '3px',
    },
  },
}))

const calculateDirectionsKm = (directions) => {
  const meters = directions.legs.reduce(
    (acc, leg) => acc + leg.distance.value,
    0
  )
  return new Intl.NumberFormat().format((meters / 1000).toFixed(1))
}

export default function RideRequestCard({ rideRequest }) {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <Typography>
        <strong>Orario</strong>: {formatRideTime(rideRequest.rideDate)}
      </Typography>
      <Typography>
        <strong>Da</strong>:{' '}
        {rideRequest.route.origin.headquarterId && (
          <BusinessIcon color={'primary'} />
        )}{' '}
        {rideRequest.route.origin.address}
      </Typography>
      <Typography>
        <strong>A</strong>:{' '}
        {rideRequest.route.destination.headquarterId && (
          <BusinessIcon color={'primary'} />
        )}{' '}
        {rideRequest.route.destination.address}
      </Typography>
      <Typography>
        <strong>Lunghezza percorso</strong>:{' '}
        {calculateDirectionsKm(rideRequest.route.rideRouteDirections)} km
      </Typography>
      {rideRequest.deletedAt && (
        <Typography color="error">
          <strong>Eliminato il</strong>:{' '}
          {new Date(rideRequest.deletedAt).toLocaleString()}
        </Typography>
      )}
    </Card>
  )
}

RideRequestCard.propTypes = {
  rideRequest: T.object.isRequired,
}
