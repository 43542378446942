import React from 'react'
import T from 'prop-types'
import { FormControl, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import ArchivedCompanyFilter from '../../molecules/ArchivedCompanyFilter'
import FilterBar from '../FilterBar/FilterBar'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    boxShadow: 'none',
  },
  selectRoot: {
    width: theme.spacing(35),
  },
  action: {
    margin: theme.spacing(0, 1),
  },
  inputsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(0, 1),
    },
  },
  rightInputs: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function CompanyFilterBar({ filters, setFilters, onAddClick }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const onFiltersChange = (e) =>
    setFilters((f) => ({
      ...f,
      [e.target.name]:
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    }))

  const controls = (
    <>
      <ArchivedCompanyFilter
        archived={filters?.archived}
        onChange={onFiltersChange}
      />

      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.selectRoot}
      >
        <TextField
          // inputProps={{ 'data-testid': `cerca-${entity}-input` }}
          onChange={onFiltersChange}
          value={filters.search}
          label={t('common.textToSearch')}
          variant="outlined"
          name="search"
          size="small"
        />
      </FormControl>
    </>
  )
  return (
    <FilterBar entity={'company'} controls={controls} onAddClick={onAddClick} />
  )
}

CompanyFilterBar.propTypes = {
  setFilters: T.func.isRequired,
  filters: T.shape({
    search: T.string,
    archived: T.bool,
  }).isRequired,
  onAddClick: T.func,
}
