import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import BackOfficeUserForm from '../organisms/BackOfficeUserForm'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

export default function BackOfficeUserDetail() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId, role, userId } = useParams()
  const { fetchedObject: company, fetchError } = useFetchCompany(companyId)

  const isNew = !userId

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew
            ? `backOfficeUserDetailPage.${role}.newTitle`
            : `backOfficeUserDetailPage.${role}.detailTitle`
        )}
      </Typography>
      {company ? (
        <>
          <Typography variant="h4">{company?.name}</Typography>
          <BackOfficeUserForm
            companyId={companyId}
            role={role}
            userId={userId}
          />
        </>
      ) : (
        <Spinner show={true} />
      )}
    </div>
  )
}
