import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Card,
  Typography,
  Grid,
  IconButton,
  Popover,
  Button,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import PrintIcon from '@mui/icons-material/Print'

import formatISO from 'date-fns/formatISO'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import { useAuth } from '../../../hooks/useAuth'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import request from '../../../lib/request'
import useSnackbar from '../Snackbar/useSnackbar'
import ExcelIcon from '../../Excelcon'
import RidesByTypeFilterBar from './RidesByTypeFilterBar'
import KPIChart from './KPIChart'
import PieChart from './PieChart'
import BarChart from './BarChart'
import InfoPopover from './InfoPopover'

const fetchRecords = async (filters) => {
  const { companyIds, sectorIds, startDate, endDate } = filters
  const from = formatISO(startDate, { representation: 'date' })
  const to = formatISO(endDate, { representation: 'date' })

  return await request.get(
    `/admin/reports/rides-by-type/totals?companyIds=${companyIds}&sectorIds=${sectorIds}&startDate=${from}&endDate=${to}`
  )
}

const getReportFileName = (filters, companiesSectors) => {
  const { companyIds } = filters
  let companyInFileName = ''
  if (companyIds.length === 1 && !companyIds.includes('all')) {
    const companyName = companiesSectors.find(
      (cs) => cs.id === companyIds[0]
    ).name
    companyInFileName = `-${companyName.replace(/[ '"/\\]/g, '_')}`
  }
  return `Up2Go${companyInFileName}-${new Date().toISOString()}`
}

const exportToExcel = async (filters, companiesSectors) => {
  const { companyIds, sectorIds, startDate, endDate } = filters
  const from = formatISO(startDate, { representation: 'date' })
  const to = formatISO(endDate, { representation: 'date' })
  const fileName = getReportFileName(filters, companiesSectors) + '.xlsx'

  await request.downloadFile(
    `/admin/reports/rides-by-type/export?companyIds=${companyIds}&sectorIds=${sectorIds}&startDate=${from}&endDate=${to}`,
    fileName
  )
}

const classesAndIds = {
  hideExporting: 'hide-exporting',
  toPdfBox: 'to-pdf-box',
  contentCard: 'contentCard',
}

const exportToPdf = async (filters, companiesSectors) => {
  const hideExportingEls = document.getElementsByClassName(
    classesAndIds.hideExporting
  )
  const cardEl = document.getElementsByClassName(classesAndIds.contentCard)[0]
  const canvasEl = document.getElementById(classesAndIds.toPdfBox)
  const canvasRatio = canvasEl.clientHeight / canvasEl.clientWidth
  const doc = new jsPDF(canvasRatio <= 1 ? 'l' : 'p', 'mm', 'a4')

  const gutter = 5
  const pageWidth = Math.floor(doc.internal.pageSize.getWidth() - gutter * 2)
  const pageHeight = Math.floor(doc.internal.pageSize.getHeight() - gutter * 2)

  const ratioHeight = pageWidth * canvasRatio
  const newHeight = Math.floor(Math.min(pageHeight, ratioHeight))
  const newWidth = Math.floor(
    newHeight === ratioHeight
      ? pageWidth
      : Math.min(pageWidth, pageHeight / canvasRatio)
  )

  const marginX = Math.floor((pageWidth - newWidth) / 2)
  const marginY = Math.floor((pageHeight - newHeight) / 2)

  cardEl.style.boxShadow = 'none'

  for (let el of hideExportingEls) {
    el.style.visibility = 'hidden'
  }

  const canvas = await html2canvas(canvasEl)

  cardEl.style.boxShadow = ''

  for (let el of hideExportingEls) {
    el.style.visibility = ''
  }

  const blob = canvas.toDataURL('image/jpg')

  const fileName = getReportFileName(filters, companiesSectors) + '.pdf'

  doc.addImage(
    blob,
    'JPEG',
    marginX + gutter,
    marginY + gutter,
    newWidth,
    newHeight,
    'NONE'
  )
  doc.save(fileName)
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
    flex: 1,
  },
}))

export default function Dashboard() {
  const { showSnackbarMessage } = useSnackbar()
  const { t, i18n } = useTranslation()
  const classes = useStyles()

  const now = useMemo(() => new Date(), [])

  const { isAdmin } = useAuth()

  const [filters, setFilters] = useState({
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: now,
    companyIds: [],
    sectorIds: [],
    archived: !isAdmin,
  })

  const [currentSearch, setCurrentSearch] = useState({
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: now,
    companyId: '',
    companyIds: [],
    sectorIds: [],
    archived: !isAdmin,
  })

  const companiesSectors = useCompaniesSectors()

  const [anchorEl, setAnchorEl] = useState(null)

  const [reportData, setReportData] = useState(null)

  const memoedFetch = useCallback(
    async (filters) => {
      try {
        const results = await fetchRecords(filters)

        setCurrentSearch(filters)
        setReportData(results)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
      }
    },
    [showSnackbarMessage, t]
  )

  const togglePopover = (event) => {
    setAnchorEl((s) => (s ? null : event.currentTarget))
  }

  useEffect(() => {
    if (companiesSectors?.length === 1) {
      setFilters((f) => ({
        ...f,
        companyIds: [companiesSectors[0].id],
      }))
    }
  }, [companiesSectors])

  const dataRides = useMemo(() => {
    if (!currentSearch.companyIds.length || !reportData) {
      return []
    }
    let carEnabled = true
    let transitEnabled = true
    let busforfunEnabled = true
    let bikeEnabled = true
    let walkEnabled = true
    let smartWorkingEnabled = true
    if (!currentSearch.companyIds.includes('all')) {
      const companies = currentSearch.companyIds.map((companyId) =>
        companiesSectors.find((cs) => cs.id === companyId)
      )
      carEnabled = companies.some((company) => company.carEnabled)
      transitEnabled = companies.some((company) => company.transitEnabled)
      busforfunEnabled = companies.some((company) => company.busforfunEnabled)
      bikeEnabled = companies.some((company) => company.bikeEnabled)
      walkEnabled = companies.some((company) => company.walkEnabled)
      smartWorkingEnabled = companies.some(
        (company) => company.smartWorkingEnabled
      )
    }
    return [
      ...(carEnabled || reportData?.insertedRidesWithoutPassengersAsDriver > 0
        ? [
            {
              name: t('dashboard.charts.legend.driver'),
              insertedRides: reportData?.insertedRidesWithoutPassengersAsDriver,
              completedRides:
                reportData?.completedRidesWithoutPassengersAsDriver,
              pieColor: 'rgb(26, 117, 187)',
              barColors: ['rgb(26, 117, 187)', 'rgb(26, 117, 187, 0.7)'],
            },
          ]
        : []),
      ...(carEnabled || reportData?.insertedRidesWithPassengersAsDriver > 0
        ? [
            {
              name: t('dashboard.charts.legend.carPooling'),
              insertedRides: reportData?.insertedRidesWithPassengersAsDriver,
              completedRides: reportData?.completedRidesWithPassengersAsDriver,
              pieColor: 'rgb(254, 0, 0)',
              barColors: ['rgb(254, 0, 0)', 'rgba(254, 0, 0,0.7)'],
            },
          ]
        : []),
      ...(transitEnabled || reportData?.insertedRidesAsTransit > 0
        ? [
            {
              name: t('dashboard.charts.legend.transit'),
              insertedRides: reportData?.insertedRidesAsTransit,
              completedRides: reportData?.completedRidesAsTransit,
              pieColor: 'rgb(0, 146, 71)',
              barColors: ['rgb(0, 146, 71)', 'rgba(0, 146, 71, 0.7)'],
            },
          ]
        : []),
      ...(busforfunEnabled || reportData?.insertedRidesAsBusforfun > 0
        ? [
            {
              name: t('dashboard.charts.legend.busforfun'),
              insertedRides: reportData?.insertedRidesAsBusforfun,
              completedRides: reportData?.completedRidesAsBusforfun,
              pieColor: 'rgb(0, 200, 140)',
              barColors: ['rgb(0, 200, 140)', 'rgba(0, 200, 140, 0.7)'],
            },
          ]
        : []),
      ...(bikeEnabled || reportData?.insertedRidesAsBiker > 0
        ? [
            {
              name: t('dashboard.charts.legend.biker'),
              insertedRides: reportData?.insertedRidesAsBiker,
              completedRides: reportData?.completedRidesAsBiker,
              pieColor: 'rgb(248, 147, 31)',
              barColors: ['rgb(248, 147, 31)', 'rgba(248, 147, 31, 0.7)'],
            },
          ]
        : []),
      ...(reportData?.completedRidesAsBikerViaRentals > 0
        ? [
            {
              name: t('dashboard.charts.legend.bikerViaRentals'),
              insertedRides: reportData?.completedRidesAsBikerViaRentals,
              completedRides: reportData?.completedRidesAsBikerViaRentals,
              pieColor: 'rgb(248, 110 ,31)',
              barColors: ['rgb(248, 110, 31)', 'rgba(248, 110, 31, 0.7)'],
            },
          ]
        : []),
      ...(walkEnabled || reportData?.insertedRidesAsWalk > 0
        ? [
            {
              name: t('dashboard.charts.legend.walk'),
              insertedRides: reportData?.insertedRidesAsWalk,
              completedRides: reportData?.completedRidesAsWalk,
              pieColor: 'rgb(146, 88, 24)',
              barColors: ['rgb(146, 88, 24)', 'rgba(146, 88, 24, 0.7)'],
            },
          ]
        : []),
      ...(smartWorkingEnabled || reportData?.insertedRidesAsSmartWorking > 0
        ? [
            {
              name: t('dashboard.charts.legend.smartWorking'),
              insertedRides: reportData?.insertedRidesAsSmartWorking,
              completedRides: reportData?.completedRidesAsSmartWorking,
              pieColor: 'rgb(144, 39, 142)',
              barColors: ['rgb(144, 39, 142)', 'rgba(144, 39, 142,0.7)'],
            },
          ]
        : []),
    ]
  }, [reportData, currentSearch, companiesSectors, t])

  const dataKPI = useMemo(
    () => [
      {
        name: t('dashboard.charts.legend.totalUsersCount'),
        info: t('dashboard.charts.info.totalUsersCount'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length && reportData?.totalUsersCount) || 0
        ),
      },
      {
        name: t('dashboard.charts.legend.activeUsersCount'),
        info: t('dashboard.charts.info.activeUsersCount'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length && reportData?.activeUsersCount) || 0
        ),
      },
      {
        name: t('dashboard.charts.legend.activePopulation'),
        info: t('dashboard.charts.info.activePopulation'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length &&
            reportData?.activePopulation * 100) ||
            0
        ),
      },
      {
        name: t('dashboard.charts.legend.savedCO2'),
        info: t('dashboard.charts.info.savedCO2'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length && reportData?.savedCo2) || 0
        ),
      },
      {
        name: t('dashboard.charts.legend.sustainableKms'),
        info: t('dashboard.charts.info.sustainableKms'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length && reportData?.sustainableKms) || 0
        ),
      },
      {
        name: t('dashboard.charts.legend.totalSustainableRides'),
        info: t('dashboard.charts.info.totalSustainableRides'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length &&
            reportData?.totalSustainableRides) ||
            0
        ),
      },
      {
        name: t('dashboard.charts.legend.credits'),
        info: t('dashboard.charts.info.credits'),
        value: Intl.NumberFormat(i18n.language, {
          maximumFractionDigits: 0,
        }).format(
          (currentSearch.companyIds.length && reportData?.credits) || 0
        ),
      },
    ],

    [i18n.language, currentSearch, reportData, t]
  )

  const selectedCompanyNames = currentSearch.companyIds.length
    ? currentSearch.companyIds.includes('all')
      ? t('heatMap.filter.allCompanies')
      : currentSearch.companyIds
          .map(
            (companyId) =>
              companiesSectors.find((cs) => cs.id === companyId).name
          )
          .join(', ')
    : null

  return (
    <Box id={classesAndIds.toPdfBox}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h3">{t('homePage.title')}</Typography>
        <IconButton
          onClick={togglePopover}
          className={classesAndIds.hideExporting}
          size="large"
        >
          {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          onClose={togglePopover}
          disableRestoreFocus
        >
          <RidesByTypeFilterBar
            searchHandler={(filters) => {
              memoedFetch(filters)
              togglePopover()
            }}
            filters={filters}
            setFilters={setFilters}
            companiesSectors={companiesSectors}
          />
        </Popover>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="body2">
          <span>{`${t('dashboard.filter.from')}: `}</span>
          <span>
            {(currentSearch?.startDate?.getTime() &&
              new Intl.DateTimeFormat().format(currentSearch.startDate)) ||
              ''}
          </span>
          {', '}
          <span>{`${t('dashboard.filter.to')}: `}</span>
          <span>
            {(currentSearch?.endDate?.getTime() &&
              new Intl.DateTimeFormat().format(currentSearch.endDate)) ||
              ''}
          </span>
          {selectedCompanyNames && ` - ${selectedCompanyNames}`}
        </Typography>
        {reportData && (
          <Box display="flex" className={classesAndIds.hideExporting}>
            <Button
              color="primary"
              startIcon={<ExcelIcon />}
              onClick={() => exportToExcel(currentSearch, companiesSectors)}
            >
              {t('dashboard.exportExcel')}
            </Button>
            <Button
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => exportToPdf(currentSearch, companiesSectors)}
            >
              {t('dashboard.exportPdf')}
            </Button>
          </Box>
        )}
      </Box>

      <Card className={`${classes.root} ${classesAndIds.contentCard}`}>
        <Box>
          <KPIChart data={dataKPI} />
          <Box mb={2} />
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" alignContent="center">
                <Typography align="center">
                  <strong>{t('dashboard.charts.pieEnteredRides.title')}</strong>
                </Typography>
                <Box pl={1} />
                <InfoPopover>
                  {t('dashboard.charts.info.enteredRides')}
                </InfoPopover>
              </Box>
              <PieChart
                data={dataRides}
                dataKey="insertedRides"
                colorKey="pieColor"
                percent
              />
            </Grid>
            <Grid item xs={6}>
              <Box display="flex" justifyContent="center" alignContent="center">
                <Typography align="center">
                  <strong>
                    {t('dashboard.charts.pieCompletedRides.title')}
                  </strong>
                </Typography>
                <Box pl={1} />
                <InfoPopover>
                  {t('dashboard.charts.info.completedRides')}
                </InfoPopover>
              </Box>
              <PieChart
                data={dataRides}
                dataKey="completedRides"
                colorKey="pieColor"
                percent
              />
            </Grid>
            <Grid item xs={12}>
              <Box mb={4} />
              <Box display="flex" justifyContent="center" alignContent="center">
                <Typography>
                  <strong>
                    {t('dashboard.charts.barRidesPartition.title')}
                  </strong>
                </Typography>
                <Box pl={1} />
                <InfoPopover>
                  {t('dashboard.charts.info.ridesPartition')}
                </InfoPopover>
              </Box>
              <BarChart
                data={dataRides}
                dataKeys={['completedRides', 'insertedRides']}
                colorsKey="barColors"
              />
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  )
}
