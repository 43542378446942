import React, { useState } from 'react'
import {
  Toolbar,
  AppBar as MuiAppBar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import AccountCircle from '@mui/icons-material/AccountCircle'

import { Auth } from 'aws-amplify'
import { useAuth } from '../../hooks/useAuth'

const isQAEnv = process.env.REACT_APP_ENV === 'QA'

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    whiteSpace: 'nowrap',
    backgroundColor: isQAEnv ? 'red' : 'default',
    '&::before': isQAEnv
      ? {
          content: '"- TEST - TEST - TEST - TEST - TEST - TEST -"',
          whiteSpace: 'nowrap',
          display: 'inline-block',
          textAlign: 'center',
          fontWeight: 'bold',
          color: 'black',
        }
      : {},
  },
}))

export default function AppBar() {
  const classes = useStyles()

  const { user } = useAuth()

  const [anchorEl, setAnchorEl] = useState(null)

  const toggleMenu = (e) => setAnchorEl(anchorEl ? null : e.currentTarget)

  const logout = () => {
    setAnchorEl(null)
    Auth.signOut({ global: true })
  }

  return (
    <MuiAppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Typography variant="h6" className={classes.title}>
          Up2go - {user?.firstName} {user?.lastName}
        </Typography>

        <IconButton
          color="inherit"
          onClick={toggleMenu}
          aria-label="account button"
          size="large"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="appbar-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={toggleMenu}
        >
          <MenuItem onClick={logout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </MuiAppBar>
  )
}

AppBar.propTypes = {}
