import * as Yup from 'yup'

export const RewardsSchema = (t) => {
  const invalidValue = t('common.invalidValue')
  const fieldRequired = t('common.fieldRequired')

  return Yup.object().shape({
    credits: Yup.number()
      .typeError(invalidValue)
      .integer(invalidValue)
      .required(fieldRequired),
  })
}
