import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchSectors({ companyId }) {
  const get = useCallback(async () => {
    const sectors = await request.get(`/admin/sectors?companyId=${companyId}`)
    return sectors?.items
  }, [companyId])
  return useFetch(get)
}
