import React from 'react'
import T from 'prop-types'
import { Card, CardContent, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    boxShadow: 'none',
  },
  selectRoot: {
    width: theme.spacing(35),
  },
  action: {
    margin: theme.spacing(0, 1),
  },
  inputsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(0, 1),
    },
  },
  rightInputs: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function FilterBar({ entity, controls = null, onAddClick }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.inputsRow}>
          {controls}

          {onAddClick && (
            <div className={classes.rightInputs}>
              <Button
                color="primary"
                className={classes.action}
                onClick={onAddClick}
              >
                Crea {t(`common.${entity}`)}
              </Button>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

FilterBar.propTypes = {
  entity: T.string,
  controls: T.object,
  onAddClick: T.func,
}
