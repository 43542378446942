import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import HeatMap from '../organisms/HeatMap'

export default function HeatMapPage() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('heatMap.title')}</Typography>
      <HeatMap />
    </div>
  )
}
