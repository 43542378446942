import React, { useEffect, useState } from 'react'
import { Box, Button, Container } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import config from '../../../config'
import useFetchCompany from '../../../hooks/useFetchCompany'

import request from '../../../lib/request'
import { reloadImageOnError } from '../../../lib/image-reloader'
import Checkbox from '../../atoms/Checkbox'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import UploadDialog from '../UploadDialog'
import { MessagingSettingsSchema } from './validators'

const LOGO_FIELD = 'messagingCompanyLogo'
const imageRelativeFolder = 'uploads/logo/'

export default function MessagingSettings({ companyId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()
  const [uploadDialogField, setUploadDialogField] = useState(null)

  const [pageMode, setPageMode] = useState('view')
  const readOnly = pageMode === 'view'

  const reloadLogoOnError = reloadImageOnError()

  const {
    fetchedObject: company,
    isFetching: isFetchingCompany,
    fetchError: fetchCompanyError,
    refetch: refetchCompany,
  } = useFetchCompany(companyId)

  useEffect(() => {
    if (fetchCompanyError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchCompanyError}`,
      })
    }
  }, [fetchCompanyError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        messagingEnabled: values.messagingEnabled,
        messagingCompanyLogo: values.messagingCompanyLogo,
      }

      await request.patch(`/admin/companies/${companyId}`, data)
      actions.setSubmitting(false)
      setPageMode('view')
      Object.assign(company, data)

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      let message, autoHideDuration
      switch (e.response.data.errorCode) {
        case 'valid_domains_collision':
          message =
            t('entities.company.collidingDomains') +
            e.response.data.collidingCompanies
              .map((company) => company.name)
              .join(', ')
          autoHideDuration = 15000
          break
        case 'valid_domains_required':
          message = t('entities.company.domainsRequired')
          break
        default:
          message = e.response.data?.message || e.message
      }

      showSnackbarMessage({
        open: true,
        severity: 'error',
        autoHideDuration,
        message,
      })
      actions.setSubmitting(false)
    }
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  const saveImage = async (file) => {
    if (!uploadDialogField) {
      console.error('Error: uploadDialogField is null!') // eslint-disable-line
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')}`,
      })
      return
    }
    await request.patch(`/admin/companies/${companyId}`, {
      [uploadDialogField]: `/${imageRelativeFolder}${file.newFileName}`,
    })
    setUploadDialogField(null)
    return refetchCompany()
  }

  const deleteImage = async (field) => {
    await request.patch(`/admin/companies/${companyId}`, {
      [field]: '',
    })
    return refetchCompany()
  }

  return isFetchingCompany ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={900} ml={0}>
      <Formik
        validationSchema={MessagingSettingsSchema(t)}
        initialValues={{
          messagingEnabled: company?.messagingEnabled || false,
          messagingCompanyLogo: company?.messagingCompanyLogo || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <Box style={{ margin: '0 0 16px 0' }}>
              <Checkbox
                inputProps={{
                  disabled: readOnly,
                }}
                color="primary"
                label={t('entities.company.messagingEnabled')}
                name="messagingEnabled"
              />
            </Box>

            <Box>
              <div>
                <label
                  style={{
                    verticalAlign: 'bottom',
                    margin: 'auto 16px auto auto',
                  }}
                >
                  {t('messagingSettings.messagingCompanyLogo')}:
                </label>
              </div>
              {company?.messagingCompanyLogo && (
                <>
                  <div>
                    <img
                      src={`${config.upload.baseUrl}${company.messagingCompanyLogo}`}
                      alt="messagingCompanyLogo"
                      style={{ maxHeight: '225px', maxWidth: '300px' }}
                      onError={reloadLogoOnError}
                    />
                  </div>
                  {isAdmin && readOnly && (
                    <Button
                      onClick={() => deleteImage(LOGO_FIELD)}
                      color="primary"
                      aria-label={t('common.deleteImage')}
                    >
                      {t('common.deleteImage')}
                    </Button>
                  )}
                </>
              )}
              {isAdmin && readOnly && (
                <Button
                  onClick={() => setUploadDialogField(LOGO_FIELD)}
                  color="primary"
                  aria-label={t('common.chooseImage')}
                >
                  {t('common.chooseImage')}
                </Button>
              )}
            </Box>

            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="reset"
                    color="secondary"
                    aria-label={t('common.cancel')}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
      <UploadDialog
        options={{
          open: !!uploadDialogField,
          handleClose: () => setUploadDialogField(false),
          handleSave: saveImage,
          relativePath: imageRelativeFolder,
        }}
      />
    </Box>
  )
}

MessagingSettings.propTypes = {
  companyId: T.string.isRequired,
}
