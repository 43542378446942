import React, { useCallback, useState } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Button, Card, Container } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import formatISO from 'date-fns/formatISO'
import { useHistory } from 'react-router-dom'

import request from '../../../lib/request'
import useSnackbar from '../Snackbar/useSnackbar'

import SearchArea from './SearchArea'
import RidesArea from './RidesArea'

const fetchRidesAndRequests = async (filters) => {
  const { date, driverUser, requesterUser } = filters
  const dateStr = formatISO(date, { representation: 'date' })
  return await request.get(
    `/admin/ride-match-analysis/get-rides-and-requests?date=${dateStr}&driverId=${driverUser.id}&requesterId=${requesterUser.id}`
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
}))

export default function RideMatchAnalysis({ companyId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()
  const classes = useStyles()

  const [rides, setRides] = useState([])
  const [rideRequests, setRideRequests] = useState([])

  const [filters, setFilters] = useState({
    date: new Date(),
    driverUser: null,
    requesterUser: null,
  })
  const [filtersForRidesArea, setFiltersForRidesArea] = useState(null)

  const memoedFetchRidesAndRequests = useCallback(
    async (filters) => {
      try {
        const result = await fetchRidesAndRequests(filters)
        setRides(result.rides)
        setRideRequests(result.rideRequests)
        setFiltersForRidesArea({ ...filters })
        window.scrollTo(0, document.body.scrollHeight)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
      }
    },
    [showSnackbarMessage, t]
  )

  return (
    <>
      <Card className={classes.root}>
        <SearchArea
          searchHandler={memoedFetchRidesAndRequests}
          filters={filters}
          setFilters={setFilters}
          companyId={companyId}
        />
      </Card>

      {!!filtersForRidesArea && (
        <RidesArea
          rides={rides}
          rideRequests={rideRequests}
          filters={filtersForRidesArea}
        />
      )}

      <Container className={classes.buttons}>
        <Button
          onClick={() => history.goBack()}
          color="secondary"
          aria-label={t('common.back')}
        >
          {t('common.back')}
        </Button>
      </Container>
    </>
  )
}

RideMatchAnalysis.propTypes = {
  companyId: T.string.isRequired,
}
