import React from 'react'
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material'
import { useField } from 'formik'
import T from 'prop-types'

export default function Checkbox({ label, ...props }) {
  const [field] = useField(props)

  return (
    <FormControlLabel
      control={<MuiCheckbox checked={field.value} {...field} {...props} />}
      label={label}
    />
  )
}

Checkbox.propTypes = {
  label: T.string,
}
