const baseAppUrl = window.location.origin

export const oauthConfig = {
  domain: process.env.REACT_APP_AMPLIFY_COGNITO_DOMAIN,
  scope: [
    'aws.cognito.signin.user.admin',
    'email',
    'openid',
    'phone',
    'profile',
  ],
  redirectSignIn: baseAppUrl + '/auth-return-uri',
  redirectSignOut: baseAppUrl + '/logout',
  responseType: 'code',
}

const config = {
  server: {
    baseUrl: process.env.REACT_APP_SERVER_BASE_URL,
  },
  Amplify: {
    Auth: {
      region: process.env.REACT_APP_AMPLIFY_REGION,
      userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
      userPoolWebClientId:
        process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
      oauth: oauthConfig,
    },
  },
  upload: {
    maxFileSize: 500 * 1024, // 500 Kb
    baseUrl: process.env.REACT_APP_UPLOAD_BASE_URL,
  },
}

export default config
