import * as Yup from 'yup'

export const MessageSchema = (t) => {
  const fieldRequired = t('common.fieldRequired')

  return Yup.object().shape({
    targetCompanyIds: Yup.array()
      .of(Yup.string().required(fieldRequired))
      .required(fieldRequired)
      .min(1, fieldRequired),
    channel: Yup.string().required(fieldRequired),
    title: Yup.string().required(fieldRequired),
    body: Yup.string().when('channel', {
      is: (channel) => channel === 'EMAIL',
      then: Yup.string().required(fieldRequired),
    }),
  })
}
