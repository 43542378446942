import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchChallenge(challengeId) {
  const fetchChallenge = useCallback(async () => {
    if (!challengeId) {
      return null
    }
    return request.get(`/admin/challenges/${challengeId}`)
  }, [challengeId])

  return useFetch(fetchChallenge)
}
