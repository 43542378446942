import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchRideTypes() {
  const get = useCallback(async () => {
    const { rideTypes } = await request.get(`/admin/ride-types`)
    return rideTypes
  }, [])
  return useFetch(get)
}
