import React from 'react'
import T from 'prop-types'
import { Button, Card, CardContent } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

export const STATUS_WAIT_DROP = 'STATUS_WAIT_DROP'
export const STATUS_UPLOADING = 'STATUS_UPLOADING'
export const STATUS_COMPLETE = 'STATUS_COMPLETE'
export const STATUS_ERROR = 'STATUS_ERROR'

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'column',
    border: '1px dashed',
    width: '200px',
    height: '80px',
    padding: '0 10px',
  },
}))

const DropAreaWidget = ({
  status,
  // progress,
  getInputProps,
  getRootProps,
  dropText,
  uploadText,
  onCancel,
  cancelText,
  errorButtonText,
  errorText,
  onErrorAck,
  // ...rest
}) => {
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        {status === STATUS_WAIT_DROP && (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {dropText}
          </div>
        )}
        {status === STATUS_UPLOADING && (
          <>
            {uploadText}
            <Button onClick={onCancel} color="primary" aria-label={cancelText}>
              {cancelText}
            </Button>
          </>
        )}
        {status === STATUS_ERROR && (
          <>
            {errorText}
            <Button
              onClick={onErrorAck}
              color="primary"
              aria-label={errorButtonText}
            >
              {errorButtonText}
            </Button>
          </>
        )}
      </CardContent>
    </Card>
  )
}

export default DropAreaWidget

DropAreaWidget.propTypes = {
  status: T.string,
  // progress,
  getInputProps: T.func,
  getRootProps: T.func,
  dropText: T.string,
  uploadText: T.string,
  onCancel: T.func,
  cancelText: T.string,
  errorButtonText: T.string,
  errorText: T.string,
  onErrorAck: T.func,
}
