import React, { useEffect } from 'react'
import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import useFetchUser from '../../hooks/useFetchUser'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

import Spinner from '../atoms/Spinner'
import RideTable from '../organisms/RideTable'

export default function Rides() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { userId } = useParams()
  const history = useHistory()
  const { fetchedObject: user, isFetching, fetchError } = useFetchUser(userId)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('ridesPage.title')}</Typography>
      <Typography variant="h4">
        {`${user?.firstName} ${user?.lastName}`}
      </Typography>
      <RideTable userId={userId} />
      <Button
        onClick={() => history.goBack()}
        color="secondary"
        aria-label={t('common.back')}
      >
        {t('common.back')}
      </Button>
    </div>
  )
}
