import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchRentalProviders() {
  const fetchRentalProviders = useCallback(async () => {
    return request.get('/admin/rental-providers')
  }, [])

  return useFetch(fetchRentalProviders)
}
