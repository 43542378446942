import React from 'react'
import T from 'prop-types'

import AddBox from '@mui/icons-material/AddBox'
import ArrowDownward from '@mui/icons-material/ArrowDownward'
import Check from '@mui/icons-material/Check'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import ChevronRight from '@mui/icons-material/ChevronRight'
import Clear from '@mui/icons-material/Clear'
import DeleteOutline from '@mui/icons-material/DeleteOutline'
import Edit from '@mui/icons-material/Edit'
import FilterList from '@mui/icons-material/FilterList'
import FirstPage from '@mui/icons-material/FirstPage'
import LastPage from '@mui/icons-material/LastPage'
import Remove from '@mui/icons-material/Remove'
import SaveAlt from '@mui/icons-material/SaveAlt'
import Search from '@mui/icons-material/Search'
import ViewColumn from '@mui/icons-material/ViewColumn'

import MaterialTable from '@material-table/core'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(3),
  },
}))

export default function Table(props) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <MaterialTable
        {...props}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          search: false,
          toolbar: false,
          pageSize: 20,
          pageSizeOptions: [20, 50, 100],
          idSynonym: 'id',
          ...(props.options || {}),
        }}
        localization={t('materialTable', { returnObjects: true })}
        icons={{
          Add: AddBox,
          Check: Check,
          Clear: Clear,
          Delete: DeleteOutline,
          DetailPanel: ChevronRight,
          Edit: Edit,
          Export: SaveAlt,
          Filter: FilterList,
          FirstPage: FirstPage,
          LastPage: LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          ResetSearch: Clear,
          Search: Search,
          SortArrow: ArrowDownward,
          ThirdStateCheck: Remove,
          ViewColumn: ViewColumn,
        }}
      />
    </div>
  )
}

Table.propTypes = {
  options: T.object,
}
