import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
} from '@mui/material'
import { useField } from 'formik'
import T from 'prop-types'

export default function Select({ label, children, ...props }) {
  const [field, meta] = useField(props)

  return (
    <FormControl error={!!meta.error}>
      <InputLabel id={field.name}>{label}</InputLabel>
      <NativeSelect id={field.name} variant="outlined" {...field} {...props}>
        {children}
      </NativeSelect>
      {!!meta.error && <FormHelperText>{meta.error}</FormHelperText>}
    </FormControl>
  )
}

Select.propTypes = {
  label: T.string,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
}
