import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import CompanyTable from '../organisms/CompanyTable'

export default function Companies() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('companiesPage.title')}</Typography>
      <CompanyTable />
    </div>
  )
}
