import React from 'react'
import { Route } from 'react-router-dom'
import T from 'prop-types'
import { useAuth } from '../../hooks/useAuth'

const ProtectedRoute = ({ children, ...props }) => {
  const { canLoginToBackoffice } = useAuth()

  return <Route {...props}>{canLoginToBackoffice ? children : null}</Route>
}

export default ProtectedRoute

ProtectedRoute.propTypes = {
  children: T.node,
}
