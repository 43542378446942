import React from 'react'
import { TextField as MuiTextField } from '@mui/material'
import { useField } from 'formik'
import T from 'prop-types'

export default function TextField({ label, ...props }) {
  const [field, meta] = useField(props)

  return (
    <MuiTextField
      // FormHelperTextProps={{
      //   'data-testid': 'error-message',
      // }}
      label={label}
      variant="outlined"
      error={!!(meta.touched && meta.error)}
      helperText={meta.error}
      {...field}
      {...props}
    />
  )
}

TextField.propTypes = {
  label: T.string,
}
