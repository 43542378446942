import React, { useCallback, useEffect, useState } from 'react'
import T from 'prop-types'
import { FormControl, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import ArchivedCompanyFilter from './ArchivedCompanyFilter'
import MultiCompanyFilter from './MultiCompanyFilter'

const useStyles = makeStyles((theme) => ({
  dates: {
    width: theme.spacing(30),
  },
  multiCompanyRoot: {
    margin: theme.spacing(2, 1),
    width: theme.spacing(60),
  },
  multiCompanyFormControl: { width: theme.spacing(60) },
  multiCompanySelectedBox: {
    marginTop: '20px',
  },
}))

function selectAllHasChanged(filters, valueArr) {
  return (
    (filters.companyIds.includes('all') && !valueArr.includes('all')) ||
    (!filters.companyIds.includes('all') && valueArr.includes('all'))
  )
}

export default function GenericCompanyFilter({
  filters,
  setFilters,
  companiesSectors,
}) {
  const [companies, setCompanies] = useState([])
  const [companiesById, setCompaniesById] = useState({})

  const { t } = useTranslation()

  const classes = useStyles()

  const setSingleFilter = useCallback(
    (filterName, filterValue) => {
      setFilters((f) => ({ ...f, [filterName]: filterValue }))
    },
    [setFilters]
  )

  const onCompanyChange = (event) => {
    const { value } = event.target

    // On autofill we get a the stringified value.
    const valueArr = typeof value === 'string' ? value.split(',') : value

    if (selectAllHasChanged(filters, valueArr)) {
      // Reset other checkboxes if the "all" checbox has changed
      setSingleFilter(
        'companyIds',
        valueArr.filter((companyId) => companyId === 'all')
      )
    } else {
      setSingleFilter(
        'companyIds',
        valueArr.filter((companyId) => companyId !== 'all')
      )
    }
  }

  useEffect(() => {
    const allCompaniesSectors = [
      ...(companiesSectors?.length > 1
        ? [
            {
              id: 'all',
              name: t('heatMap.filter.allCompanies'),
              archivedAt: null,
            },
          ]
        : []),
      ...companiesSectors,
    ]

    const filteredCompanies = allCompaniesSectors
      .filter((companySectors) => {
        return filters?.archived ? true : !companySectors?.archivedAt
      })
      .map((companySectors) => ({
        id: companySectors.id,
        name: companySectors.name,
        archivedAt: companySectors.archivedAt,
      }))
    setCompanies(filteredCompanies)

    setCompaniesById(
      allCompaniesSectors.reduce((acc, companySectors) => {
        acc[companySectors.id] = companySectors.name
        return acc
      }, {})
    )

    if (companiesSectors.length === 1 && !filters?.companyIds?.length) {
      setSingleFilter('companyIds', [companiesSectors[0].id])
    }

    if (!filters?.archived && filters?.companyIds?.length) {
      const newCompanyIds = filters.companyIds.filter((companyId) =>
        filteredCompanies.find((company) => company.id === companyId)
      )
      if (newCompanyIds.length !== filters.companyIds.length) {
        setSingleFilter('companyIds', newCompanyIds)
      }
    }
  }, [
    filters?.archived,
    companiesSectors,
    filters?.companyIds,
    setSingleFilter,
    t,
  ])

  const onArchivedCompaniesChange = (e) => {
    setSingleFilter(e.target.name, e.target.checked)
  }

  return (
    <>
      <div>
        <FormControl variant="outlined" size="small" className={classes.dates}>
          <DatePicker
            label={t('heatMap.filter.from')}
            value={filters.startDate}
            onChange={(newDate) => {
              setSingleFilter('startDate', newDate)
            }}
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            slotProps={{
              field: { size: 'small' },
            }}
          />
        </FormControl>

        <FormControl variant="outlined" size="small" className={classes.dates}>
          <DatePicker
            margin="dense"
            label={t('heatMap.filter.to')}
            value={filters.endDate}
            onChange={(newDate) => setSingleFilter('endDate', newDate)}
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            slotProps={{
              field: { size: 'small' },
            }}
          />
        </FormControl>
      </div>

      <Box m={2} mb={1}>
        <ArchivedCompanyFilter
          archived={filters?.archived}
          onChange={onArchivedCompaniesChange}
        />
      </Box>

      <MultiCompanyFilter
        companies={companies}
        onChange={onCompanyChange}
        companyIds={filters.companyIds}
        showSelected={true}
        companiesById={companiesById}
        classes={classes}
      />
    </>
  )
}

GenericCompanyFilter.propTypes = {
  filters: T.object.isRequired,
  setFilters: T.func.isRequired,
  companiesSectors: T.array.isRequired,
}
