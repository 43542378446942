import { useEffect, useState } from 'react'
import { Loader } from '@googlemaps/js-api-loader'

const MAP_ID = '9e63a331adc31188'

const useGoogleMap = ({ googleMapsKey, mapElementId, mapId = MAP_ID }) => {
  const [map, setMap] = useState(null)
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false)

  useEffect(() => {
    if (!googleMapsKey || !mapElementId) {
      return
    }

    const loader = new Loader({
      apiKey: googleMapsKey,
      language: 'it',
      region: 'it',
      libraries: ['places', 'visualization', 'marker'],
    })

    loader.load().then(() => {
      const googleMap = new window.google.maps.Map(
        document.getElementById(mapElementId),
        {
          zoom: 15,
          mapId,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
        }
      )
      setMap(googleMap)
      setGoogleApiLoaded(true)
    })
  }, [googleMapsKey, mapElementId, mapId])

  return { map, googleApiLoaded }
}

export default useGoogleMap
