import React from 'react'
import { Image, useTheme } from '@aws-amplify/ui-react'
import { Box } from '@mui/material'
import logo from '../../../assets/images/logo.png'

export default function AuthHeader() {
  const { tokens } = useTheme()

  return (
    <Box textAlign="center" padding={`0 ${tokens.space.large}`}>
      <Image alt="Up2Go logo" src={logo} width={200} />
    </Box>
  )
}
