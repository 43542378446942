import React from 'react'
import { Route } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import UnauthorizedPage from '../pages/UnauthorizedPage'

const UnauthorizedRoute = (props) => {
  const { canLoginToBackoffice, user } = useAuth()

  return (
    <Route {...props}>
      {user && !canLoginToBackoffice ? <UnauthorizedPage /> : null}
    </Route>
  )
}

export default UnauthorizedRoute

UnauthorizedRoute.propTypes = {}
