import { useCallback, useEffect, useReducer } from 'react'

const initialState = {
  fetchedObject: null,
  isFetching: true,
  fetchError: null,
}

function reducer(state, action) {
  switch (action.type) {
    case 'startFetching':
      return { fetchedObject: null, isFetching: true, fetchError: null }
    case 'setFetchedObject':
      return {
        fetchedObject: action.payload,
        isFetching: false,
        fetchError: null,
      }
    case 'setError':
      return {
        fetchedObject: null,
        isFetching: false,
        fetchError: action.payload,
      }
    default:
      throw new Error()
  }
}

export default function useFetch(fetcherFn) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const refetch = useCallback(async () => {
    dispatch({ type: 'startFetching' })
    try {
      const result = await fetcherFn()
      dispatch({ type: 'setFetchedObject', payload: result })
    } catch (e) {
      dispatch({ type: 'setError', payload: e.message })
    }
  }, [fetcherFn])

  useEffect(() => {
    refetch()
  }, [refetch])

  return { ...state, refetch }
}
