import { useContext } from 'react'

import { ConfigContext } from '../configProvider'

const useConfig = () => {
  const result = useContext(ConfigContext)
  return result.context
}

export default useConfig
