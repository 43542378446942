import T from 'prop-types'
import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import request from '../../../lib/request'
import Spinner from '../../atoms/Spinner'
import CheckboxFilter from '../../molecules/CheckboxFilter'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'

const fetchRides = async (query, filters, userId) => {
  const search = filters.search || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''
  const { items: data, total: totalCount } = await request.get(
    `/admin/rides?userId=${userId}&search=${search}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page
  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const postForceCheckout = async (travellerId) => {
  await request.post(`/admin/rides/force-checkout?travellerId=${travellerId}`)
}

const deleteTraveller = async (travellerId) => {
  await request.delete(`/admin/rides?travellerId=${travellerId}`)
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function RideTable({ userId }) {
  const [filters, setFilters] = useState({ search: '', showFutureRides: false })
  const [showSpinnerOnTravellerId, setShowSpinnerOnTravellerId] = useState(null)
  const tableRef = useRef()
  const { openConfirmDialog } = useConfirmDialog()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const columns = [
    {
      title: t('ridesPage.tableHeaders.rideDate'),
      field: 'rideDate',
      type: 'datetime',
      cellStyle: { whiteSpace: 'nowrap' },
      headerStyle: { whiteSpace: 'nowrap' },
    },
    { title: t('ridesPage.tableHeaders.rideTypeId'), field: 'rideTypeId' },
    { title: t('ridesPage.tableHeaders.origin'), field: 'origin' },
    { title: t('ridesPage.tableHeaders.destination'), field: 'destination' },
    {
      title: t('ridesPage.tableHeaders.travellerState'),
      field: 'travellerState',
    },
    {
      title: t('ridesPage.credits'),
      field: 'credits',
    },
    {
      title: 'Trigger',
      field: 'checkOutOrigin',
      sorting: false,
    },
    {
      title: t('ridesPage.tableHeaders.checkedInDate'),
      field: 'checkedInDate',
      type: 'datetime',
      cellStyle: { whiteSpace: 'nowrap' },
      headerStyle: { whiteSpace: 'nowrap' },
    },
    {
      title: t('ridesPage.tableHeaders.checkedOutDate'),
      field: 'checkedOutDate',
      type: 'datetime',
      cellStyle: { whiteSpace: 'nowrap' },
      headerStyle: { whiteSpace: 'nowrap' },
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      try {
        const result = await fetchRides(query, filters, userId)
        return {
          ...result,
          data: result.data
            .map((ride) => ({
              ...ride,
              rideDate: new Date(ride.rideDate),
              rideTypeId:
                ride.travellerType === 'PASSENGER'
                  ? `${ride.rideTypeId} (request)`
                  : ride.rideTypeId,
              travellerState: ride.travellerState,
              checkOutOrigin: ride.checkOutOrigin,
            }))
            .filter((ride) =>
              filters.showFutureRides ? true : ride.rideDate < new Date()
            ),
        }
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      }
    },
    [filters, userId, showSnackbarMessage, t]
  )

  const forceCheckout = async (travellerId) => {
    openConfirmDialog({
      title: t(`ridesPage.forceCheckoutConfirmDialog.title`),
      description: t(`ridesPage.forceCheckoutConfirmDialog.description`),
      handleClose: null,
      handleConfirm: async () => {
        try {
          setShowSpinnerOnTravellerId(travellerId)
          await postForceCheckout(travellerId)
          tableRef.current.onQueryChange(filters, () => {
            setShowSpinnerOnTravellerId(null)
          })
        } catch (e) {
          setShowSpinnerOnTravellerId(null)
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: `${t('common.unexpectedError')} ${e.message}`,
          })
        }
      },
    })
  }

  const confirmDeleteTraveller = async (traveller) => {
    const { travellerId, travellerType, rideTypeId } = traveller
    const labelKey = rideTypeId.startsWith('CAR') ? travellerType : 'ECO'
    openConfirmDialog({
      title: t(`ridesPage.deleteTravellerConfirmDialog.title`),
      description: t(
        `ridesPage.deleteTravellerConfirmDialog.${labelKey}.description`
      ),
      handleClose: null,
      handleConfirm: async () => {
        try {
          setShowSpinnerOnTravellerId(travellerId)
          await deleteTraveller(travellerId)
          tableRef.current.onQueryChange(filters, () => {
            setShowSpinnerOnTravellerId(null)
          })
        } catch (e) {
          setShowSpinnerOnTravellerId(null)
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: `${t('common.unexpectedError')} ${e.message}`,
          })
        }
      },
    })
  }

  const rideDetails = (rowData) => {
    history.push(`${location.pathname}/${rowData.id}`)
  }

  const onShowFutureRidesChange = (e) => {
    setFilters((f) => ({
      ...f,
      showFutureRides: e.target.checked,
    }))
  }

  return (
    <>
      <CheckboxFilter
        name={'showFutureRides'}
        label={t('ridesPage.filter.showFutureRides')}
        checked={filters.showFutureRides}
        onChange={onShowFutureRidesChange}
      />
      <Card className={classes.root}>
        <Table
          style={{ boxShadow: 'none' }}
          tableRef={tableRef}
          columns={columns}
          components={{
            Action,
          }}
          title=""
          data={memoedFetch}
          options={{
            idSynonym: 'travellerId',
          }}
          actions={[
            {
              icon: '',
              text: t('rideDetailPage.details'),
              onClick: (e, rowData) => rideDetails(rowData),
            },
            {
              icon: '',
              text: t('ridesPage.forceCheckout'),
              onClick: (e, rowData) => forceCheckout(rowData.travellerId),
              hidden: (rowData) =>
                rowData.canBeDeleted ||
                rowData.rideDate > new Date() ||
                !['REQUESTED', 'CONFIRMED', 'CHECKED_IN'].includes(
                  rowData.travellerState
                ) ||
                rowData.travellerId === showSpinnerOnTravellerId,
            },
            {
              icon: '',
              text: t('ridesPage.deleteTraveller'),
              onClick: (e, rowData) => confirmDeleteTraveller(rowData),
              hidden: (rowData) =>
                !rowData.canBeDeleted ||
                rowData.travellerId === showSpinnerOnTravellerId,
            },
            {
              render: () => <Spinner show={true} />,
              hidden: (rowData) =>
                rowData.travellerId !== showSpinnerOnTravellerId,
            },
          ]}
        />
      </Card>
    </>
  )
}

RideTable.propTypes = {
  userId: T.string.isRequired,
}
