import { Hub } from 'aws-amplify'
import { useEffect, useState } from 'react'

export default function useAuthEvent() {
  const [authEvent, setAuthEvent] = useState({})

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      setAuthEvent(payload)
    })
    return () => Hub.remove('auth')
  }, [])

  return authEvent
}
