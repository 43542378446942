import React from 'react'
import { useAuthenticator, useTheme, View } from '@aws-amplify/ui-react'
import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function AuthLoginFooter() {
  const { toResetPassword } = useAuthenticator()
  const { tokens } = useTheme()
  const { t } = useTranslation()

  return (
    <>
      <View textAlign="center" margin={'-20px 0 20px'}>
        <Button
          fontWeight="normal"
          onClick={toResetPassword}
          size="small"
          variation="link"
        >
          {t('auth.forgotPassword')}
        </Button>
      </View>
      <Box textAlign="center" paddingBottom={`${tokens.space.large}`}>
        <Button variant="contained">
          <Link
            to="/sso-email"
            style={{ color: 'inherit', textDecoration: 'none' }}
          >
            {t('auth.ssoLogin')}
          </Link>
        </Button>
      </Box>
    </>
  )
}
