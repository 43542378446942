import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchUser(userId) {
  const fetchUser = useCallback(async () => {
    if (!userId) {
      return null
    }
    return request.get(`/admin/users/${userId}`)
  }, [userId])

  return useFetch(fetchUser)
}
