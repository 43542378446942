import T from 'prop-types'
import React from 'react'

const spinnerStyle = {
  marginLeft: '5px',
  float: 'left',
  width: '34px',
  height: '34px',
  background: 'url(/images/spinner.gif) center no-repeat',
}

export default function Spinner({ show }) {
  if (show) {
    return <div style={spinnerStyle} />
  }
  return false
}

Spinner.propTypes = {
  show: T.bool.isRequired,
}
