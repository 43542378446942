import React from 'react'
import T from 'prop-types'

export default function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

TabPanel.propTypes = {
  children: T.node,
  index: T.any.isRequired,
  value: T.any.isRequired,
}
