import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Container,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItemText,
  Paper,
  Typography,
  List,
  FormControlLabel,
  Checkbox,
} from '@mui/material'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { makeStyles } from '@mui/styles'
import endOfDay from 'date-fns/endOfDay'
import startOfDay from 'date-fns/startOfDay'
import T from 'prop-types'

import { useConfirmDialog } from '../../../hooks/useConfirmDialog'
import useFetchRideTypes from '../../../hooks/useFetchRideTypes'

import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import useFetch from '../../../hooks/useFetch'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import useFetchHeadquarters from '../../../hooks/useFetchHeadquarters'
import useFetchSectors from '../../../hooks/useFetchSectors'
import { ChallengeSchema } from './validators'

const useStyles = makeStyles((theme) => ({
  menuHeadquarters: {
    height: 400,
  },
}))

export default function ChallengeForm({ companyId, challengeId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const classes = useStyles()

  const rideTypes = useFetchRideTypes()
  const headquarters = useFetchHeadquarters({ companyId })
  const sectors = useFetchSectors({ companyId })

  const isNew = !challengeId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  // Component naming:
  const componentName = 'Challenge' // Use for translation keys and error messages

  // Data fetching:
  const fetchChallenge = useCallback(async () => {
    if (!challengeId) {
      return null
    }

    return request.get(`/admin/challenges/${challengeId}`)
  }, [challengeId])

  // Data handling:
  const {
    fetchedObject: challenge,
    isFetching,
    fetchError,
  } = useFetch(fetchChallenge)

  // Error handling:
  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.fetchError_${componentName}`, {
          error: fetchError.message,
        }),
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  // API calls:
  async function handleDownload(e) {
    e.preventDefault()
    const challengeName = challenge.name.replace(/[ '"/\\]/g, '_')
    const fileName = `${challengeName}-${new Date().toISOString()}.xlsx`
    await request.downloadFile(
      `/admin/challenges/${
        challenge.id
      }/report?timezoneOffset=${new Date().getTimezoneOffset()}`,
      fileName
    )
  }

  async function handleSubmit(values, actions) {
    try {
      const data = {
        // Adapt data structure for challenge creation/update
        companyId,
        name: values.name,
        // attrs: {startDate: format(values.startDate, 'yyyy-MM-dd'), endDate: format(values.endDate, 'yyyy-MM-dd')},
        attrs: {
          startDate: startOfDay(values.startDate).toISOString(),
          endDate: endOfDay(values.endDate).toISOString(),
          publicationDate: startOfDay(values.publicationDate).toISOString(),
          rideTypeIds: values.rideTypeIds,
        },
        headquarterIds: values.headquarterIds.map((head) => head.id),
        sectorIds: values.sectorIds.map((head) => head.id),
        // ...other challenge-specific fields
      }

      if (isNew) {
        // Adjust API endpoint for challenge creation
        const { id } = await request.post(`/admin/challenges`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/challenges/${id}`)
      } else {
        // Adjust API endpoint for challenge update
        await request.patch(`/admin/challenges/${challengeId}`, {
          ...data,
          id: challengeId,
        })
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(challenge, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (error) {
      console.error(error.message) // eslint-disable-line
      if (error.response.data.errorCode === 'overlaps')
        return showSnackbarMessage({
          open: true,
          severity: 'error',
          message:
            t(`common.requestError_overlaps`) +
            '. ' +
            t(`common.requestError_overlaps_challenges`) +
            error.response.data.overlappingChallengeNames.join(', '),
        })

      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: t(`common.requestError_${componentName}`, {
          error: error.message,
        }),
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('challengeDetailPage.deleteConfirmDescription', {
        name: challenge?.name,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/challenges/${challengeId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/challenges`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleRideTypeChange(event, values, setFieldValue, setFieldTouched) {
    setFieldTouched('rideTypeIds', true)
    if (event.target.checked) {
      setFieldValue('rideTypeIds', [...values.rideTypeIds, event.target.value])
    } else {
      setFieldValue(
        'rideTypeIds',
        values.rideTypeIds.filter((value) => value !== event.target.value)
      )
    }
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Formik
        innerRef={formikRef}
        validationSchema={ChallengeSchema(t)}
        initialValues={{
          name: challenge?.name || '',
          publicationDate: challenge?.attrs?.publicationDate
            ? new Date(challenge.attrs.publicationDate)
            : '',
          startDate: challenge?.attrs?.startDate
            ? new Date(challenge.attrs.startDate)
            : '',
          endDate: challenge?.attrs?.endDate
            ? new Date(challenge.attrs.endDate)
            : '',
          rideTypeIds: [...(rideTypes?.fetchedObject || [])].filter(
            (rideTypeId) => challenge?.attrs?.rideTypeIds?.includes(rideTypeId)
          ),
          headquarterIds: [...(headquarters?.fetchedObject || [])].filter(
            (headerquarter) =>
              challenge?.headquarterIds.indexOf(headerquarter.id) > -1
          ),
          sectorIds: [...(sectors?.fetchedObject || [])].filter(
            (sector) => challenge?.sectorIds.indexOf(sector.id) > -1
          ),
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({
          isValid,
          dirty,
          isSubmitting,
          setFieldValue,
          setFieldTouched,
          values,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Stack spacing={2} marginTop={3}>
                <TextField
                  fullWidth
                  margin="dense"
                  required
                  inputProps={{
                    autoComplete: 'off',
                    readOnly,
                  }}
                  label={t('entities.challenge.name')}
                  name="name"
                  size="small"
                />
                <Box display="flex" gap={2}>
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.publicationDate')}
                    name="publicationDate"
                    autoOk
                    readOnly={readOnly}
                    value={
                      values.publicationDate
                        ? new Date(values.publicationDate)
                        : null
                    }
                    onChange={(newDate) =>
                      setFieldValue('publicationDate', newDate)
                    }
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                      required: true,
                    }}
                  />
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.startDate')}
                    name="startDate"
                    autoOk
                    readOnly={readOnly}
                    value={values.startDate ? new Date(values.startDate) : null}
                    onChange={(newDate) => setFieldValue('startDate', newDate)}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                      required: true,
                    }}
                  />
                  <DatePicker
                    margin="dense"
                    label={t('entities.challenge.endDate')}
                    name="endDate"
                    autoOk
                    readOnly={readOnly}
                    value={values.endDate ? new Date(values.endDate) : null}
                    onChange={(newDate) => setFieldValue('endDate', newDate)}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    size="small"
                    required
                    slotProps={{
                      textField: {
                        required: true,
                      },
                    }}
                    inputProps={{
                      autoComplete: 'off',
                      readOnly,
                      size: 'small',
                    }}
                  />
                </Box>
                <Box>
                  <fieldset>
                    <legend>{t('challengeDetailPage.rideTypes')}</legend>
                    {rideTypes?.fetchedObject?.map((rideTypeId) => (
                      <FormControlLabel
                        key={rideTypeId}
                        control={
                          <Checkbox
                            checked={values.rideTypeIds?.includes(rideTypeId)}
                            inputProps={{
                              disabled: readOnly,
                            }}
                            color="primary"
                            name="rideTypeIds"
                            value={rideTypeId}
                            onChange={(e) =>
                              handleRideTypeChange(
                                e,
                                values,
                                setFieldValue,
                                setFieldTouched
                              )
                            }
                          />
                        }
                        label={t(`entities.rideTypes.${rideTypeId}`)}
                      />
                    ))}
                    {errors.rideTypeIds && touched.rideTypeIds && (
                      <div
                        style={{
                          color: '#d32f2f',
                          fontSize: '0.75rem',
                        }}
                      >
                        {errors.rideTypeIds}
                      </div>
                    )}
                  </fieldset>
                </Box>
                <Box display={'flex'} gap={2}>
                  {!readOnly && headquarters.fetchedObject ? (
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="headquarterId-label">
                          {t('backOfficeUserDetailPage.headquarters')}
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.menuHeadquarters }}
                          labelId="headquarterId-label"
                          label={'Sedi'}
                          name="headquarterIds"
                          multiple
                          value={values.headquarterIds}
                          renderValue={(selected) => {
                            return selected
                              ? selected.map((head) => head.name).join(', ')
                              : t('common.select')
                          }}
                          disabled={!!readOnly}
                          onChange={({ target }) => {
                            const { value } = target
                            if (value[value.length - 1] === 'all') {
                              setFieldValue(
                                'headquarterIds',
                                values.headquarterIds.length ===
                                  headquarters.fetchedObject.length
                                  ? []
                                  : headquarters.fetchedObject
                              )
                              return
                            }
                            setFieldValue('headquarterIds', value)
                          }}
                        >
                          <MenuItem value={'all'}>
                            <Checkbox
                              name="all"
                              checked={
                                values.headquarterIds.length ===
                                headquarters.fetchedObject.length
                              }
                            />
                            <ListItemText primary={t('common.selectAll')} />
                          </MenuItem>
                          {headquarters.fetchedObject.map((c) => {
                            return (
                              <MenuItem key={c.id} value={c}>
                                <Checkbox
                                  name="subsidiary"
                                  checked={
                                    !!values.headquarterIds.find(
                                      (subsidiary) => subsidiary.id === c.id
                                    )
                                  }
                                />
                                <ListItemText primary={c.name} />
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    readOnly &&
                    headquarters.fetchedObject && (
                      <>
                        <Paper
                          sx={{
                            width: '100%',
                            padding: '0px 8px 0px 8px',
                            position: 'relative',
                            '&:after': {
                              content: '" "',
                              position: 'absolute',
                              bottom: 0,
                              background:
                                'linear-gradient(to bottom, transparent, white)',
                              height: '50px',
                              width: '100%',
                            },
                          }}
                        >
                          <Typography variant="h6">
                            {t('challengeDetailPage.headquarters')}
                          </Typography>
                          <List
                            className="headertersList"
                            sx={{
                              position: 'relative',
                              overflow: 'auto',
                              maxHeight: 300,
                              paddingTop: 0,
                              paddingBottom: '32px',
                              '& ul': { padding: 0 },
                            }}
                          >
                            {values.headquarterIds.map((headquarter) => (
                              <li key={headquarter.id}>{headquarter.name}</li>
                            ))}
                          </List>
                        </Paper>
                      </>
                    )
                  )}
                  {!readOnly && sectors.fetchedObject ? (
                    <Box sx={{ width: '100%' }}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="sectorId-label">
                          {t('challengeDetailPage.sectors')}
                        </InputLabel>
                        <Select
                          MenuProps={{ className: classes.menuHeadquarters }}
                          labelId="sectorId-label"
                          label={'Settori'}
                          name="sectorIds"
                          multiple
                          value={values.sectorIds}
                          renderValue={(selected) => {
                            return selected
                              ? selected.map((head) => head.name).join(', ')
                              : t('common.select')
                          }}
                          disabled={!!readOnly}
                          onChange={({ target }) => {
                            const { value } = target
                            if (value[value.length - 1] === 'all') {
                              setFieldValue(
                                'sectorIds',
                                values.sectorIds.length ===
                                  sectors.fetchedObject.length
                                  ? []
                                  : sectors.fetchedObject
                              )
                              return
                            }
                            setFieldValue('sectorIds', value)
                          }}
                        >
                          <MenuItem value={'all'}>
                            <Checkbox
                              name="all"
                              checked={
                                values.sectorIds.length ===
                                sectors.fetchedObject.length
                              }
                            />
                            <ListItemText primary={t('common.selectAll')} />
                          </MenuItem>
                          {sectors.fetchedObject.map((c) => {
                            return (
                              <MenuItem key={c.id} value={c}>
                                <Checkbox
                                  name="sector"
                                  checked={
                                    !!values.sectorIds?.find(
                                      (sector) => sector.id === c.id
                                    )
                                  }
                                />
                                <ListItemText primary={c.name} />
                              </MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Box>
                  ) : (
                    readOnly &&
                    sectors.fetchedObject && (
                      <>
                        <Paper
                          sx={{
                            width: '100%',
                            padding: '0px 8px 0px 8px',
                            position: 'relative',
                            '&:after': {
                              content: '" "',
                              position: 'absolute',
                              bottom: 0,
                              background:
                                'linear-gradient(to bottom, transparent, white)',
                              height: '50px',
                              width: '100%',
                            },
                          }}
                        >
                          <Typography variant="h6">
                            {t('challengeDetailPage.sectors')}
                          </Typography>
                          <List
                            className="headertersList"
                            sx={{
                              position: 'relative',
                              overflow: 'auto',
                              maxHeight: 300,
                              paddingTop: 0,
                              paddingBottom: '32px',
                              '& ul': { padding: 0 },
                            }}
                          >
                            {values.sectorIds.map((sector) => (
                              <li key={sector.id}>{sector.name}</li>
                            ))}
                          </List>
                        </Paper>
                      </>
                    )
                  )}
                </Box>
                <Typography variant="p" sx={{ marginTop: '4px !important' }}>
                  {t('challengeDetailPage.help_sectors_headquarters')}
                </Typography>
              </Stack>

              <Container>
                {readOnly ? (
                  <>
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                    {isAdmin && (
                      <>
                        <Button
                          onClick={handleEdit}
                          color="primary"
                          aria-label={t('common.edit')}
                        >
                          {t('common.edit')}
                        </Button>
                        <Button
                          onClick={handleDelete}
                          color="primary"
                          aria-label={t('common.delete')}
                        >
                          {t('common.delete')}
                        </Button>
                      </>
                    )}
                    <Button
                      onClick={handleDownload}
                      color="primary"
                      aria-label={t('common.report')}
                    >
                      {t('common.report')}
                    </Button>
                  </>
                ) : (
                  <>
                    {isNew ? (
                      <Button
                        onClick={() => history.goBack()}
                        color="secondary"
                        aria-label={t('common.back')}
                      >
                        {t('common.back')}
                      </Button>
                    ) : (
                      <Button
                        type="reset"
                        color="secondary"
                        aria-label={t('common.cancel')}
                      >
                        {t('common.cancel')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={!isValid || !dirty || isSubmitting}
                      color="primary"
                    >
                      {t('common.save')}
                    </Button>
                  </>
                )}
              </Container>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

ChallengeForm.propTypes = {
  companyId: T.string,
  challengeId: T.string,
}
