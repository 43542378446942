import React, { useCallback, useEffect, useState } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import request from '../../../lib/request'
import useSnackbar from '../Snackbar/useSnackbar'
import MatchArea from './MatchArea'

import RideCard from './RideCard'
import RideRequestCard from './RideRequestCard'

const fetchMatchAnalysis = async ({ rideId, rideRequestId }) => {
  return await request.get(
    `/admin/ride-match-analysis/analyse?rideId=${rideId}&rideRequestId=${rideRequestId}`
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '1000px',
    margin: 'auto',
  },
  columnHeader: {
    fontWeight: 'bold',
  },
  verifyButton: {
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
}))

export default function RidesArea({ rides, rideRequests, filters }) {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const [selectedRideId, setSelectedRideId] = useState(null)
  const [selectedRideRequestId, setSelectedRideRequestId] = useState(null)
  const [matchInfo, setMatchInfo] = useState(null)

  useEffect(() => {
    if (!selectedRideId && rides?.length) {
      setSelectedRideId(rides[0].id)
    }
    if (!selectedRideRequestId && rideRequests?.length) {
      setSelectedRideRequestId(rideRequests[0].id)
    }
  }, [rides, rideRequests, selectedRideId, selectedRideRequestId])

  useEffect(() => {
    setMatchInfo(null)
  }, [rides, selectedRideId, selectedRideRequestId])

  const memoedFetchMatchAnalysis = useCallback(
    async ({ rideId, rideRequestId }) => {
      try {
        const { matchInfo } = await fetchMatchAnalysis({
          rideId,
          rideRequestId,
        })
        setMatchInfo(matchInfo)
        window.scrollTo(0, document.body.scrollHeight)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
      }
    },
    [showSnackbarMessage, t]
  )

  return (
    <Grid
      container
      spacing={3}
      justifyContent={'flex-start'}
      className={classes.root}
    >
      <Grid item xs={6}>
        <Box textAlign="center">
          <Typography className={classes.columnHeader}>
            {t('rideMatchAnalysis.ridesOf')} {filters.driverUser.firstName}{' '}
            {filters.driverUser.lastName}
          </Typography>
        </Box>
        {!rides.length ? (
          <div>{t('rideMatchAnalysis.noRideFound')}</div>
        ) : (
          <RadioGroup
            row
            aria-label="theme"
            value={selectedRideId}
            onChange={(e) => setSelectedRideId(e.target.value)}
          >
            {rides.map((ride) => (
              <FormControlLabel
                key={ride.id}
                value={ride.id}
                control={<Radio />}
                label={<RideCard ride={ride} />}
              />
            ))}
          </RadioGroup>
        )}
      </Grid>

      <Grid item xs={6} style={{ borderLeft: '1px dotted grey' }}>
        <Box textAlign="center">
          <Typography className={classes.columnHeader}>
            {t('rideMatchAnalysis.rideRequestsOf')}{' '}
            {filters.requesterUser.firstName} {filters.requesterUser.lastName}
          </Typography>
        </Box>
        {!rides.length ? (
          <div>{t('rideMatchAnalysis.noRideRequestFound')}</div>
        ) : (
          <RadioGroup
            row
            aria-label="theme"
            value={selectedRideRequestId}
            onChange={(e) => setSelectedRideRequestId(e.target.value)}
          >
            {rideRequests.map((rideRequest) => (
              <FormControlLabel
                key={rideRequest.id}
                value={rideRequest.id}
                control={<Radio />}
                label={<RideRequestCard rideRequest={rideRequest} />}
              />
            ))}
          </RadioGroup>
        )}
      </Grid>

      <Grid container className={classes.verifyButton}>
        <Button
          color="primary"
          disabled={!selectedRideId || !selectedRideRequestId}
          onClick={() =>
            memoedFetchMatchAnalysis({
              rideId: selectedRideId,
              rideRequestId: selectedRideRequestId,
            })
          }
        >
          {t('rideMatchAnalysis.verify')}
        </Button>
      </Grid>

      {!!matchInfo && (
        <MatchArea
          matchInfo={matchInfo}
          filters={filters}
          ride={rides.find((r) => r.id === selectedRideId)}
          rideRequest={rideRequests.find(
            (rr) => rr.id === selectedRideRequestId
          )}
        />
      )}
    </Grid>
  )
}

RidesArea.propTypes = {
  rides: T.array.isRequired,
  rideRequests: T.array.isRequired,
  filters: T.object.isRequired,
}
