import React, { useCallback, useEffect } from 'react'
import { Box, Button, Container } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import useFetch from '../../../hooks/useFetch'
import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'

export default function UserForm({ userId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const fetchUser = useCallback(async () => {
    return request.get(`/admin/users/${userId}`)
  }, [userId])

  const { fetchedObject: user, isFetching, fetchError } = useFetch(fetchUser)

  const readOnly = true

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('userDetailPage.deleteConfirmDescription', {
        firstName: user?.firstName,
        lastName: user?.lastName,
      }),
      confirmText: 'DELETE',
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/users/${userId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/users`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Formik
        initialValues={{
          firstName: user.firstName || '',
          lastName: user.lastName || '',
          email: user.email,
          companyName: user.companyName || '',
          headquarterName: user.headquarterName || '',
          phone: user.phone || '',
          locale: user.locale || '',
          address: user.address || '',
          zipCode: user.zipCode || '',
          city: user.city || '',
          carColor: user.carColor || '',
          carModel: user.carModel || '',
          availableSeats: user.availableSeats || '',
          engineName: user.engineName || '',
          signupDate: user.signupDate
            ? new Date(user.signupDate).toLocaleString()
            : '',
          privacyAcceptanceDate: user.privacyAcceptanceDate
            ? new Date(user.privacyAcceptanceDate).toLocaleString()
            : '',
        }}
      >
        <Form>
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.lastName')}
            name="lastName"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.firstName')}
            name="firstName"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.email')}
            name="email"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.companyName')}
            name="companyName"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.headquarterName')}
            name="headquarterName"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.phone')}
            name="phone"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.locale')}
            name="locale"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.address')}
            name="address"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.zipCode')}
            name="zipCode"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.city')}
            name="city"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.carColor')}
            name="carColor"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.carModel')}
            name="carModel"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.availableSeats')}
            name="availableSeats"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.engineName')}
            name="engineName"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.signupDate')}
            name="signupDate"
            size="small"
          />
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly,
            }}
            label={t('entities.user.privacyAcceptanceDate')}
            name="privacyAcceptanceDate"
            size="small"
          />

          <Container>
            <Button
              onClick={() => history.goBack()}
              color="secondary"
              aria-label={t('common.back')}
            >
              {t('common.back')}
            </Button>
            {isAdmin && (
              <>
                <Button
                  onClick={handleDelete}
                  color="primary"
                  aria-label={t('common.delete')}
                >
                  {t('common.delete')}
                </Button>
              </>
            )}
          </Container>
        </Form>
      </Formik>
    </Box>
  )
}

UserForm.propTypes = {
  userId: T.string.isRequired,
}
