import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useFetchCompany from '../../../hooks/useFetchCompany'
import useFetchIdentityProviders from '../../../hooks/useFetchIdentityProviders'

import request from '../../../lib/request'
import Select from '../../atoms/Select'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import { CompanyTechSchema } from './validators'

export default function CompanyTechForm({ companyId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const [pageMode, setPageMode] = useState('view')
  const readOnly = pageMode === 'view'

  const {
    fetchedObject: company,
    isFetching: isFetchingCompany,
    fetchError: fetchCompanyError,
  } = useFetchCompany(companyId)

  const {
    fetchedObject: identityProviders,
    isFetching: isFetchingIdentityProviders,
    fetchError: fetchIdentityProvidersError,
  } = useFetchIdentityProviders()

  const identityProviderNames =
    company?.identityProviderName &&
    identityProviders &&
    !identityProviders?.includes(company.identityProviderName)
      ? identityProviders.concat(company.identityProviderName).sort()
      : identityProviders || []

  useEffect(() => {
    if (fetchCompanyError || fetchIdentityProvidersError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${
          fetchCompanyError || fetchIdentityProvidersError
        }`,
      })
    }
  }, [fetchCompanyError, fetchIdentityProvidersError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        identityProviderName: values.identityProviderName,
      }

      await request.patch(`/admin/companies/${companyId}`, data)
      actions.setSubmitting(false)
      setPageMode('view')
      Object.assign(company, data)

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      let message, autoHideDuration
      switch (e.response.data.errorCode) {
        case 'valid_domains_collision':
          message =
            t('entities.company.collidingDomains') +
            e.response.data.collidingCompanies
              .map((company) => company.name)
              .join(', ')
          autoHideDuration = 15000
          break
        case 'valid_domains_required':
          message = t('entities.company.domainsRequired')
          break
        default:
          message = e.response.data?.message || e.message
      }

      showSnackbarMessage({
        open: true,
        severity: 'error',
        autoHideDuration,
        message,
      })
      actions.setSubmitting(false)
    }
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetchingCompany ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={900} ml={0}>
      <Typography variant="h6" color="error">
        {t('companyTechDetail.warning')}
      </Typography>

      <Formik
        validationSchema={CompanyTechSchema(t)}
        initialValues={{
          identityProviderName: company?.identityProviderName || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            {!isFetchingIdentityProviders && (
              <Box style={{ margin: '0 0 16px 0' }}>
                <label
                  style={{
                    verticalAlign: 'bottom',
                    margin: 'auto 16px auto auto',
                  }}
                >
                  Single Sign-On:
                </label>

                <Select name="identityProviderName" disabled={readOnly}>
                  <option value={''}>No</option>
                  {identityProviderNames.map((idpName) => (
                    <option key={idpName} value={idpName}>
                      {idpName}
                    </option>
                  ))}
                </Select>
              </Box>
            )}

            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Button
                    type="reset"
                    color="secondary"
                    aria-label={t('common.cancel')}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

CompanyTechForm.propTypes = {
  companyId: T.string.isRequired,
}
