import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UserTable from '../organisms/UserTable'

export default function Users() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('usersPage.title')}</Typography>
      <UserTable />
    </div>
  )
}
