import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchIdentityProviders() {
  const fetchIdentityProviders = useCallback(async () => {
    return request.get('/admin/identity-providers/available')
  }, [])

  return useFetch(fetchIdentityProviders)
}
