import React from 'react'
import T from 'prop-types'

import { useTranslation } from 'react-i18next'

import CheckboxFilter from './CheckboxFilter'

export default function ArchivedCompanyFilter({ archived, onChange }) {
  const { t } = useTranslation()

  return (
    <CheckboxFilter
      name={'archived'}
      label={t('dashboard.filter.includeArchivedCompanies')}
      checked={archived}
      onChange={onChange}
    />
  )
}

ArchivedCompanyFilter.propTypes = {
  archived: T.bool.isRequired,
  onChange: T.func.isRequired,
}
