import T from 'prop-types'
import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import HistoryFilterBar from './HistoryFilterBar'

const fetchHistory = async (query, filters, companyId, t) => {
  const search = filters.search || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''

  const { items, total: totalCount } = await request.get(
    `/admin/history?companyId=${companyId}&search=${search}&page=${page}&pageSize=${pageSize}`
  )

  const data = items.map((entity) => ({
    ...entity,
    entityTypeCell: t(
      `historyPage.tableCellValues.entityType.${entity?.entityType}`
    ),
    actionCell: t(
      `historyPage.tableCellValues.actionType.${entity?.actionType}`
    ),
    author: {
      ...entity?.author,
      name: `${entity?.author?.lastName} ${entity?.author?.firstName}`,
    },
  }))

  const offset = query.pageSize * query.page

  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function HistoryTable({ companyId }) {
  const history = useHistory()
  const { isAdmin } = useAuth()

  const [filters, setFilters] = useState({ search: '' })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    {
      title: t('historyPage.tableHeaders.entityType'),
      field: 'entityTypeCell',
      sorting: false,
    },
    {
      title: t('historyPage.tableHeaders.entityName'),
      field: 'entity.name',
      sorting: false,
    },
    {
      title: t('historyPage.tableHeaders.actionType'),
      field: 'actionCell',
      sorting: false,
    },
    {
      title: t('historyPage.tableHeaders.author'),
      field: 'author.name',
      sorting: false,
    },
    {
      title: t('historyPage.tableHeaders.actionDate'),
      field: 'actionDate',
      sorting: false,

      type: 'datetime',
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      try {
        return await fetchHistory(query, filters, companyId, t)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      }
    },
    [filters, companyId, showSnackbarMessage, t]
  )

  const onAddClick = () => {
    return isAdmin ? () => history.push(`/company/${companyId}/history`) : null
  }

  return (
    <Card className={classes.root}>
      <HistoryFilterBar
        filters={filters}
        setFilters={setFilters}
        onAddClick={onAddClick()}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
      />
    </Card>
  )
}

HistoryTable.propTypes = {
  companyId: T.string.isRequired,
}
