import * as React from 'react'
import T from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function CoordsConfirmDialog({
  isOpen,
  handleClose,
  handleConfirm,
}) {
  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('coordsConfirmDialog.title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('coordsConfirmDialog.description')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('common.cancel')}</Button>
          <Button onClick={handleConfirm} autoFocus>
            {t('common.confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

CoordsConfirmDialog.propTypes = {
  isOpen: T.bool.isRequired,
  handleClose: T.func.isRequired,
  handleConfirm: T.func,
}
