import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Box, Button, Container } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import useFetch from '../../../hooks/useFetch'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import { SectorSchema } from './validators'

export default function SectorForm({ companyId, sectorId }) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const isNew = !sectorId
  const [pageMode, setPageMode] = useState(isNew ? 'edit' : 'view')
  const readOnly = pageMode === 'view'

  const fetchSector = useCallback(async () => {
    if (!sectorId) {
      return null
    }
    return request.get(`/admin/sectors/${sectorId}`)
  }, [sectorId])

  const {
    fetchedObject: sector,
    isFetching,
    fetchError,
  } = useFetch(fetchSector)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        name: values.name,
        companyId,
      }

      if (isNew) {
        await request.post(`/admin/sectors`, data)
        actions.setSubmitting(false)
        history.replace(`/company/${companyId}/sectors`)
      } else {
        await request.patch(`/admin/sectors/${sectorId}`, data)
        actions.setSubmitting(false)
        setPageMode('view')
        Object.assign(sector, data)
      }

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: e.message,
      })
      actions.setSubmitting(false)
    }
  }

  async function handleDelete(e) {
    e.preventDefault()
    openConfirmDialog({
      title: t('common.deleteConfirmTitle'),
      description: t('sectorDetailPage.deleteConfirmDescription', {
        name: sector?.name,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.delete(`/admin/sectors/${sectorId}`)
          showSnackbarMessage({ open: true, severity: 'success' })
          history.replace(`/company/${companyId}/sectors`)
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.message,
          })
        }
      },
    })
  }

  function handleEdit(e) {
    e.preventDefault()
    setPageMode('edit')
  }

  function handleReset() {
    setPageMode('view')
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Formik
        innerRef={formikRef}
        validationSchema={SectorSchema(t)}
        initialValues={{
          name: sector?.name || '',
        }}
        onSubmit={handleSubmit}
        onReset={handleReset}
        enableReinitialize={true}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form>
            <TextField
              fullWidth
              margin="dense"
              required
              inputProps={{
                autoComplete: 'off',
                readOnly,
              }}
              label={t('entities.sector.name')}
              name="name"
              size="small"
            />

            <Container>
              {readOnly ? (
                <>
                  <Button
                    onClick={() => history.goBack()}
                    color="secondary"
                    aria-label={t('common.back')}
                  >
                    {t('common.back')}
                  </Button>
                  {isAdmin && (
                    <>
                      <Button
                        onClick={handleEdit}
                        color="primary"
                        aria-label={t('common.edit')}
                      >
                        {t('common.edit')}
                      </Button>
                      <Button
                        onClick={handleDelete}
                        color="primary"
                        aria-label={t('common.delete')}
                      >
                        {t('common.delete')}
                      </Button>
                    </>
                  )}
                </>
              ) : (
                <>
                  {isNew ? (
                    <Button
                      onClick={() => history.goBack()}
                      color="secondary"
                      aria-label={t('common.back')}
                    >
                      {t('common.back')}
                    </Button>
                  ) : (
                    <Button
                      type="reset"
                      color="secondary"
                      aria-label={t('common.cancel')}
                    >
                      {t('common.cancel')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
            </Container>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

SectorForm.propTypes = {
  companyId: T.string.isRequired,
  sectorId: T.string,
}
