import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import UserFilterBar from './UserFilterBar'

const fetchUsers = async (query, filters) => {
  const search = filters.search || ''
  const companyIds = filters.companyIds || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''

  const { items: data, total: totalCount } = await request.get(
    `/admin/users?search=${search}&companyIds=${companyIds}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )

  const offset = query.pageSize * query.page

  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function UserTable() {
  const history = useHistory()
  const companiesSectors = useCompaniesSectors()
  const [filters, setFilters] = useState({
    search: '',
    companyIds: [],
    archived: false,
  })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    { title: t('usersPage.tableHeaders.lastName'), field: 'lastName' },
    { title: t('usersPage.tableHeaders.firstName'), field: 'firstName' },
    { title: t('usersPage.tableHeaders.email'), field: 'email' },
    { title: t('usersPage.tableHeaders.companyName'), field: 'companyName' },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    (query) => {
      return fetchUsers(query, filters).catch((e) => {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      })
    },
    [filters, showSnackbarMessage, t]
  )

  return (
    <Card className={classes.root}>
      <UserFilterBar
        filters={filters}
        setFilters={setFilters}
        companiesSectors={companiesSectors}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(`/user/${rowData.id}`)
            },
          },
        ]}
      />
    </Card>
  )
}
