import { useCallback, useContext } from 'react'
import { SnackbarContext } from '.'

export default function useSnackbar() {
  const { setSnackbarData } = useContext(SnackbarContext)

  const showSnackbarMessage = useCallback(
    (data) => setSnackbarData({ open: true, ...data }),
    [setSnackbarData]
  )

  const hideSnackbarMessage = useCallback(
    () => setSnackbarData({ open: false }),
    [setSnackbarData]
  )

  return {
    showSnackbarMessage,
    hideSnackbarMessage,
  }
}
