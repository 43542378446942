import React, { useCallback, useEffect } from 'react'
import { Box, Button, Container, Typography } from '@mui/material'
import T from 'prop-types'
import { Formik, Form } from 'formik'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useConfirmDialog } from '../../../hooks/useConfirmDialog'

import useFetch from '../../../hooks/useFetch'
import request from '../../../lib/request'
import TextField from '../../atoms/TextField'
import Spinner from '../../atoms/Spinner'
import useSnackbar from '../Snackbar/useSnackbar'
import { RewardsSchema } from './validators'

export default function RewardsForm({ userId }) {
  const { showSnackbarMessage } = useSnackbar()
  const history = useHistory()
  const { openConfirmDialog } = useConfirmDialog()
  const { t } = useTranslation()

  const { isAdmin } = useAuth()

  const fetchUser = useCallback(async () => {
    return request.get(`/admin/users/${userId}`)
  }, [userId])

  const {
    fetchedObject: user,
    isFetching: isFetchingUser,
    fetchError: fetchErrorUser,
  } = useFetch(fetchUser)

  const fetchRewards = useCallback(async () => {
    return request.get(`/admin/rewards?userId=${userId}`)
  }, [userId])

  const {
    fetchedObject: rewards,
    isFetching: isFetchingRewards,
    fetchError: fetchErrorRewards,
    refetch: refetchRewards,
  } = useFetch(fetchRewards)

  useEffect(() => {
    if (fetchErrorUser || fetchErrorRewards) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t(
          'common.unexpectedError'
        )} ${fetchErrorUser} ${fetchErrorRewards}`,
      })
    }
  }, [fetchErrorUser, fetchErrorRewards, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    openConfirmDialog({
      title: t('rewardsPage.assignConfirmTitle'),
      description: t('rewardsPage.assignConfirmDescription', {
        firstName: user?.firstName,
        lastName: user?.lastName,
      }),
      handleClose: null,
      handleConfirm: async () => {
        try {
          await request.post('/admin/rewards', {
            userId,
            credits: values.credits,
          })
          actions.setSubmitting(false)
          showSnackbarMessage({ open: true, severity: 'success' })
          await refetchRewards()
        } catch (e) {
          showSnackbarMessage({
            open: true,
            severity: 'error',
            message: e.response.data?.message || e.message,
          })
        }
      },
    })
  }

  return isFetchingUser || isFetchingRewards ? (
    <Spinner show={true} />
  ) : (
    <Box maxWidth={700} ml={0}>
      <Box my={2}>
        <Typography>
          Crediti disponibili: {rewards?.credits?.available}
        </Typography>
      </Box>
      <Typography variant="h6">{t('rewardsPage.newAssignment')}</Typography>

      <Formik
        validationSchema={RewardsSchema(t)}
        initialValues={{
          credits: '',
        }}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        <Form>
          <TextField
            fullWidth
            margin="dense"
            inputProps={{
              autoComplete: 'off',
              readOnly: false,
            }}
            label={t('entities.rewards.credits')}
            name="credits"
            size="small"
          />

          <Container>
            <Button
              onClick={() => history.goBack()}
              color="secondary"
              aria-label={t('common.back')}
            >
              {t('common.back')}
            </Button>
            {isAdmin && (
              <>
                <Button
                  type="submit"
                  color="primary"
                  aria-label={t('rewardsPage.assign')}
                >
                  {t('rewardsPage.assign')}
                </Button>
              </>
            )}
          </Container>
        </Form>
      </Formik>
    </Box>
  )
}

RewardsForm.propTypes = {
  userId: T.string.isRequired,
}
