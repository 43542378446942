import { useEffect, useState } from 'react'
import useGoogleMap from './useGoogleMap'

const addMarkers = (markers, map) => {
  return markers.map((marker) => {
    const infowindow = new window.google.maps.InfoWindow({
      content: marker.infoWindowContent,
    })

    const googleMarker = new window.google.maps.marker.AdvancedMarkerElement({
      position: marker.coords,
      map,
      title: marker.title,
    })

    googleMarker.addListener('click', () => {
      infowindow.open({
        anchor: googleMarker,
        map,
        shouldFocus: false,
      })
    })

    return googleMarker
  })
}

const useGoogleHeatMap = ({
  googleMapsKey,
  markers,
  mapElementId,
  heatMapLocations,
  ridesState,
}) => {
  const { map, googleApiLoaded } = useGoogleMap({
    googleMapsKey,
    mapElementId,
  })
  const [heatmap, setHeatmap] = useState(null)

  useEffect(() => {
    if (!googleApiLoaded || !map) {
      return
    }

    const heatmap = new window.google.maps.visualization.HeatmapLayer({
      radius: 20,
    })
    setHeatmap(heatmap)
    heatmap.setMap(map)
  }, [googleApiLoaded, map])

  useEffect(() => {
    if (!map || !heatmap) {
      return
    }

    const data = heatMapLocations?.map((location) => ({
      location: new window.google.maps.LatLng(location.lat, location.lng),
      weight: Math.sqrt(
        ridesState === 'completed'
          ? location.completedRidesWeight
          : location.allRidesWeight
      ),
    }))
    heatmap.setData(data || [])

    const googleMarkers = addMarkers(markers || [], map)

    const bounds = new window.google.maps.LatLngBounds()
    data.forEach((item) => bounds.extend(item.location))
    markers.forEach((marker) => bounds.extend(marker.coords))
    map.fitBounds(bounds) // auto-zoom
    map.panToBounds(bounds) // auto-center

    return () => googleMarkers.forEach((m) => m.setMap(null))
  }, [map, heatmap, heatMapLocations, markers, ridesState])

  return { map, heatmap }
}

export default useGoogleHeatMap
