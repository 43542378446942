import React, { useState, useRef, useCallback, useEffect } from 'react'

import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'
import config from '../../../config'
import CompanyFilterBar from './CompanyFilterBar'

const fetchCompanies = async (query, filters) => {
  const search = filters.search || ''
  const archived = filters.archived || false
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''
  const { items: data, total: totalCount } = await request.get(
    `/admin/companies?search=${search}&archived=${archived}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page
  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function CompanyTable() {
  const history = useHistory()
  const { isAdmin } = useAuth()

  const [filters, setFilters] = useState({ search: '', archived: !isAdmin })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    { title: t('companiesPage.tableHeaders.name'), field: 'name' },
    {
      title: '',
      field: 'logo',
      render: (item) => (
        <img
          alt="logo"
          style={{ maxHeight: '50px', maxWidth: '280px' }}
          src={`${config.upload.baseUrl}${item.logo}`}
        />
      ),
    },
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      try {
        const result = await fetchCompanies(query, filters)

        const data = result.data.map((company) => ({
          ...company,
          name: company.archivedAt
            ? `${company.name} (${t('companiesPage.archived')})`
            : company.name,
        }))

        return { ...result, data }
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      }
    },
    [filters, showSnackbarMessage, t]
  )

  const onAddClick = () => {
    return isAdmin ? () => history.push(`/company`) : null
  }

  return (
    <Card className={classes.root}>
      <CompanyFilterBar
        filters={filters}
        setFilters={setFilters}
        onAddClick={onAddClick()}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(`/company/${rowData.id}`)
            },
          },
        ]}
      />
    </Card>
  )
}
