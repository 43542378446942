import T from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useAuth } from './hooks/useAuth'

import request from './lib/request'

export const ConfigContext = React.createContext('config-context')

export const ConfigContextProvider = ({
  children,
  Loading,
  Error,
  ...props
}) => {
  const [state, setState] = useState({ isInit: false, error: null })
  const [active, setActive] = useState(false)
  const { canLoginToBackoffice } = useAuth()

  const setValue = (name, value) => setState((s) => ({ ...s, [name]: value }))
  const setReady = () => setState((s) => ({ ...s, isInit: true }))
  const setError = (error) => setState((s) => ({ ...s, error }))

  useEffect(() => {
    if (!canLoginToBackoffice) {
      return
    }

    const fetchConfig = async () => {
      try {
        setActive(true)
        const data = await request.get('/admin/config')
        if (active) {
          setValue('context', data)
          setReady()
        }
      } catch (e) {
        if (active) {
          setError(e.message)
        }
      }
    }

    fetchConfig()

    return () => setActive(false)
  }, [canLoginToBackoffice, active])

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...props })
    }
    return child
  })

  return (
    <ConfigContext.Provider value={state}>
      {!state.isInit && !state.error && canLoginToBackoffice && <Loading />}
      {state.error && <Error error={state.error} />}
      {state.isInit && childrenWithProps}
    </ConfigContext.Provider>
  )
}

ConfigContextProvider.propTypes = {
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
  initMethod: T.func,
  Loading: T.elementType,
  Error: T.elementType,
}
