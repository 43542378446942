import React, { isValidElement, useState } from 'react'
import T from 'prop-types'
import { Box, Popover, Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

export default function InfoPopover({ children }) {
  const [anchorEl, setAnchorEl] = useState(null)

  const togglePopover = (event) => {
    setAnchorEl((s) => (s ? null : event.currentTarget))
  }
  return (
    <Box onClick={togglePopover} sx={{ cursor: 'pointer' }}>
      <InfoIcon />
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={togglePopover}
        disableRestoreFocus
      >
        {isValidElement(children) ? (
          { children }
        ) : (
          <Box p={2} maxWidth={200}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </Popover>
    </Box>
  )
}

InfoPopover.propTypes = {
  children: T.node,
}
