import React, { useState, createContext } from 'react'
import { Snackbar as MuiSnackbar } from '@mui/material'
import { Alert } from '@mui/material'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

export const SnackbarContext = createContext()

export default function SnackbarProvider({ children }) {
  const [snackbarData, setSnackbarData] = useState({ open: false })
  const { t } = useTranslation()

  const handleSnackbarClose = () =>
    setSnackbarData((state) => ({ ...state, open: false }))

  return (
    <SnackbarContext.Provider value={{ setSnackbarData }}>
      {children}
      <MuiSnackbar
        open={snackbarData.open}
        autoHideDuration={snackbarData.autoHideDuration || 6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleSnackbarClose}
          severity={snackbarData.severity}
        >
          {snackbarData.message || t('common.operationSuccessful')}
        </Alert>
      </MuiSnackbar>
    </SnackbarContext.Provider>
  )
}

SnackbarProvider.propTypes = {
  children: T.node,
}
