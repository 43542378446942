import React from 'react'
import { SvgIcon } from '@mui/material'

export default function ExcelIcon(props) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <g>
        <path fill="none" d="M0 0h24v24H0z" />
        <path d="M2.859 2.877l12.57-1.795a.5.5 0 0 1 .571.495v20.846a.5.5 0 0 1-.57.495L2.858 21.123a1 1 0 0 1-.859-.99V3.867a1 1 0 0 1 .859-.99zM17 3h4a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1h-4V3zm-6.8 9L13 8h-2.4L9 10.286 7.4 8H5l2.8 4L5 16h2.4L9 13.714 10.6 16H13l-2.8-4z" />
      </g>
    </SvgIcon>
  )
}
