import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Divider, Toolbar, Typography, Card, CardContent } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import ChallengeForm from '../organisms/ChallengeForm'
import useSnackbar from '../organisms/Snackbar/useSnackbar'
import TeamsTable from '../organisms/TeamsTable'

export default function ChallengeDetail() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId, id } = useParams()
  const { fetchedObject: company, fetchError } = useFetchCompany(companyId)
  const isNew = !id

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew
            ? 'challengeDetailPage.newTitle'
            : 'challengeDetailPage.detailTitle'
        )}
      </Typography>
      {company ? (
        <>
          <Typography variant="h4">{company?.name}</Typography>
          {<ChallengeForm companyId={companyId} challengeId={id} />}

          <Divider sx={{ m: 3 }} />

          {id && (
            <Card>
              <CardContent>
                <Typography variant="h5">
                  {t('challengeDetailPage.teams')}
                </Typography>
                <TeamsTable challengeId={id} companyId={companyId} />
              </CardContent>
            </Card>
          )}
        </>
      ) : (
        <Spinner show={true} />
      )}
    </div>
  )
}
