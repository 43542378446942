import React from 'react'
import T from 'prop-types'
import { Card, CardContent, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import GenericCompanyFilter from '../../molecules/GenericCompanyFilter'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    boxShadow: 'none',
  },
  action: {
    margin: theme.spacing(0, 1),
  },
  inputsRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    '& .MuiFormControl-root': {
      margin: theme.spacing(0, 1),
    },
  },
  rightInputs: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function HeatMapSearchArea({
  searchHandler,
  filters,
  setFilters,
  companiesSectors,
}) {
  const { t } = useTranslation()

  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.inputsRow}>
          <GenericCompanyFilter
            filters={filters}
            setFilters={setFilters}
            searchHandler={searchHandler}
            companiesSectors={companiesSectors}
          />

          <div className={classes.rightInputs}>
            <Button
              color="primary"
              className={classes.action}
              onClick={() => searchHandler(filters)}
              disabled={!filters.companyIds.length}
            >
              {t('common.search')}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

HeatMapSearchArea.propTypes = {
  searchHandler: T.func.isRequired,
  filters: T.object.isRequired,
  setFilters: T.func.isRequired,
  companiesSectors: T.array,
}
