import React from 'react'
import T from 'prop-types'

import { TableCell, TableRow } from '@mui/material'
import SentimentDissatisfiedSharpIcon from '@mui/icons-material/SentimentDissatisfiedSharp'
import SentimentSatisfiedSharpIcon from '@mui/icons-material/SentimentSatisfiedSharp'
import { useTranslation } from 'react-i18next'

export default function MatchResultRow({
  checkName,
  checkResult,
  successMessage,
  failMessage,
}) {
  const { t } = useTranslation()

  return (
    <TableRow>
      <TableCell>{t(`rideMatchAnalysis.checks.${checkName}`)}</TableCell>
      <TableCell
        style={
          checkResult
            ? { backgroundColor: 'lightgreen' }
            : { backgroundColor: 'orangered' }
        }
      >
        {checkResult ? (
          <SentimentSatisfiedSharpIcon />
        ) : (
          <SentimentDissatisfiedSharpIcon />
        )}
      </TableCell>
      <TableCell>{checkResult ? successMessage : failMessage}</TableCell>
    </TableRow>
  )
}

MatchResultRow.propTypes = {
  checkName: T.string.isRequired,
  checkResult: T.bool.isRequired,
  successMessage: T.string.isRequired,
  failMessage: T.string.isRequired,
}
