import BusinessIcon from '@mui/icons-material/Business'
import React from 'react'
import T from 'prop-types'

import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { useTranslation } from 'react-i18next'
import { formatRideTime } from '../../../lib/formatter'

import MatchResultRow from './MatchResultRow'

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    marginBottom: theme.spacing(1),
  },
  warningIcon: { position: 'relative', top: '3px' },
}))

export default function MatchArea({ matchInfo, filters, ride, rideRequest }) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <>
      <Card className={classes.card}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('rideMatchAnalysis.checks.control')}</TableCell>
                <TableCell>{t('rideMatchAnalysis.checks.result')}</TableCell>
                <TableCell>{t('rideMatchAnalysis.checks.notes')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{t('rideMatchAnalysis.filter.driverId')}</TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography>
                    {filters.driverUser.firstName} {filters.driverUser.lastName}{' '}
                    @{formatRideTime(ride.rideDate)}
                  </Typography>
                  <Typography>
                    <strong>Da</strong>:{' '}
                    {ride.actualRoute.origin.headquarterId && (
                      <BusinessIcon
                        color={'primary'}
                        className={classes.warningIcon}
                      />
                    )}{' '}
                    {ride.actualRoute.origin.address}
                  </Typography>
                  <Typography>
                    <strong>A</strong>:{' '}
                    {ride.actualRoute.destination.headquarterId && (
                      <BusinessIcon
                        color={'primary'}
                        className={classes.warningIcon}
                      />
                    )}{' '}
                    {ride.actualRoute.destination.address}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  {t('rideMatchAnalysis.filter.requesterId')}
                </TableCell>
                <TableCell></TableCell>
                <TableCell>
                  <Typography>
                    {filters.requesterUser.firstName}{' '}
                    {filters.requesterUser.lastName} @
                    {formatRideTime(rideRequest.rideDate)}
                  </Typography>
                  <Typography>
                    <strong>Da</strong>:{' '}
                    {rideRequest.route.origin.headquarterId && (
                      <BusinessIcon
                        color={'primary'}
                        className={classes.warningIcon}
                      />
                    )}{' '}
                    {rideRequest.route.origin.address}
                  </Typography>
                  <Typography>
                    <strong>A</strong>:{' '}
                    {rideRequest.route.destination.headquarterId && (
                      <BusinessIcon
                        color={'primary'}
                        className={classes.warningIcon}
                      />
                    )}{' '}
                    {rideRequest.route.destination.address}
                  </Typography>
                </TableCell>
              </TableRow>

              <MatchResultRow
                checkName={'headquarters-at-same-edges'}
                checkResult={matchInfo['headquarters-at-same-edges'].ok}
                successMessage={
                  'Entrambi i viaggi hanno sedi come partenza o come destinazione'
                }
                failMessage={
                  "Uno dei due viaggi ha una sede come partenza mentre l'altro viaggio ha una sede come destinazione"
                }
              />
              <MatchResultRow
                checkName={'not-already-passenger'}
                checkResult={matchInfo['not-already-passenger'].ok}
                successMessage={
                  'Il richiedente non risulta già come passeggero del viaggio'
                }
                failMessage={
                  'Il richiedente attualmente risulta già come passeggero del viaggio'
                }
              />
              {matchInfo['near-ride']
                .minutesBetweenRideAndRideRequestStartDates !== undefined && (
                <MatchResultRow
                  checkName={'near-ride_checkCompatibleStartDate'}
                  checkResult={
                    matchInfo['near-ride']
                      .minutesBetweenRideAndRideRequestStartDates <=
                    matchInfo['near-ride'].rideMatchMaxMinutes
                  }
                  successMessage={`La differenza in minuti negli orari di partenza dalla sede (${matchInfo['near-ride'].minutesBetweenRideAndRideRequestStartDates} minuti) è inferiore o uguale al massimo configurato (${matchInfo['near-ride'].rideMatchMaxMinutes} minuti)`}
                  failMessage={`La differenza in minuti negli orari di partenza dalla sede (${matchInfo['near-ride'].minutesBetweenRideAndRideRequestStartDates} minuti) è superiore al massimo configurato (${matchInfo['near-ride'].rideMatchMaxMinutes} minuti)`}
                />
              )}
              {matchInfo['near-ride'].minutesFromPickUpDateToRideRequestDate !==
                undefined && (
                <MatchResultRow
                  checkName={'near-ride_checkCompatiblePickUpDate'}
                  checkResult={
                    matchInfo['near-ride']
                      .minutesFromPickUpDateToRideRequestDate <=
                    matchInfo['near-ride'].rideMatchMaxMinutes
                  }
                  successMessage={`La differenza in minuti fra l'orario di pick-up e quello di partenza del richiedente (${matchInfo['near-ride'].minutesFromPickUpDateToRideRequestDate} minuti) è inferiore o uguale al massimo configurato (${matchInfo['near-ride'].rideMatchMaxMinutes} minuti)`}
                  failMessage={`La differenza in minuti fra l'orario di pick-up e quello di partenza del richiedente (${matchInfo['near-ride'].minutesFromPickUpDateToRideRequestDate} minuti) è superiore al massimo configurato (${matchInfo['near-ride'].rideMatchMaxMinutes} minuti)`}
                />
              )}
              <MatchResultRow
                checkName={'near-ride_checkNewRideDuration'}
                checkResult={
                  matchInfo['near-ride'].extraTravelMinutes <=
                  matchInfo['near-ride'].maxExtraTravelMinutesToPickUpPassenger
                }
                successMessage={`Passando a prendere il richiedente, il viaggio dura ${matchInfo['near-ride'].extraTravelMinutes} minuti in più, che è inferiore o uguale al massimo configurato (${matchInfo['near-ride'].maxExtraTravelMinutesToPickUpPassenger} minuti)`}
                failMessage={`Passando a prendere il richiedente, il viaggio sarebbe durato ${matchInfo['near-ride'].extraTravelMinutes} minuti in più, che è superiore al massimo configurato (${matchInfo['near-ride'].maxExtraTravelMinutesToPickUpPassenger} minuti)`}
              />
            </TableBody>
          </Table>
        </TableContainer>
      </Card>

      <Box>
        <Typography>
          <ReportProblemOutlinedIcon
            color={'error'}
            className={classes.warningIcon}
          />{' '}
          Le seguenti casistiche non possono essere verificate a posteriori e
          potrebbero aver portato il richiedente a non trovare alcun match:
        </Typography>
        <ol>
          <li>
            Il viaggio poteva aver raggiunto il limite di passeggeri ammessi al
            momento del tentativo di match, dopodiché qualche passeggero
            potrebbe aver rinunciato
          </li>
          <li>
            Il viaggio potrebbe essere stato cancellato prima che il richiedente
            cercasse un match
          </li>
          <li>
            L'algoritmo di match esclude viaggi la cui data di partenza è più
            vecchia di due ore rispetto alla data e ora correnti. Ad esempio, se
            in questo momento fossero le 10 am ed un utente stesse cercando un
            viaggio per <strong>oggi</strong>, verrebbero esclusi dai risultati
            i viaggi che partono prima delle 8 am.
          </li>
          <li>
            L'algoritmo di match mostra solo i viaggi non completati: se, al
            momento del tentativo di match, il viaggio era già completato,
            allora il match non avviene
          </li>
        </ol>
      </Box>
    </>
  )
}

MatchArea.propTypes = {
  matchInfo: T.object.isRequired,
  ride: T.object.isRequired,
  rideRequest: T.object.isRequired,
  filters: T.object.isRequired,
}
