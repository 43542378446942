import T from 'prop-types'
import React, { createContext, useState, useEffect, useContext } from 'react'

const allowedRoles = ['ADMIN', 'MOBILITY_MANAGER', 'HR']
const isAdmin = (roles) => roles.includes('ADMIN')
const canLoginToBackoffice = (roles, companyArchivedAt) =>
  roles.some((role) => allowedRoles.includes(role)) &&
  (isAdmin(roles) || !companyArchivedAt)

export const AuthContext = createContext({
  user: null,
  roles: [],
  isAdmin: false,
  canLoginToBackoffice: false,
})

export const AuthProvider = ({ authData, children }) => {
  const [user, setUser] = useState(null)
  const [roles, setRoles] = useState([])
  const [companyArchivedAt, setCompanyArchivedAt] = useState(null)

  useEffect(() => {
    if (!authData) {
      setUser({})
      setRoles([])
      return
    }
    const user = {
      firstName: authData?.attributes?.given_name,
      lastName: authData?.attributes?.family_name,
      email: authData?.attributes?.email,
    }
    setUser(user)

    try {
      const claim = JSON.parse(
        authData.signInUserSession.idToken.payload[
          'https://up2go.it/jwt/claims'
        ]
      )
      user.companyId = claim.companyId
      setCompanyArchivedAt(claim.companyArchivedAt)
      setRoles(claim.roles.map((role) => role.id))
    } catch (e) {
      // console.error(e)
      setRoles([])
    }
  }, [authData])

  const value = {
    user,
    roles,
    isAdmin: isAdmin(roles),
    canLoginToBackoffice: canLoginToBackoffice(roles, companyArchivedAt),
    companyArchivedAt,
  }
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

AuthProvider.propTypes = {
  authData: T.object,
  children: T.oneOfType([T.arrayOf(T.node), T.node]).isRequired,
}
