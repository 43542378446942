import React, { useCallback, useEffect, useState } from 'react'
import T from 'prop-types'
import { Box, FormControl, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import ArchivedCompanyFilter from '../../molecules/ArchivedCompanyFilter'
import MultiCompanyFilter from '../../molecules/MultiCompanyFilter'
import FilterBar from '../FilterBar/FilterBar'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2, 0),
    margin: theme.spacing(2, 0),
    boxShadow: 'none',
  },
  multiCompanyFormControl: { width: theme.spacing(20) },
  searchRoot: {
    width: theme.spacing(35),
  },
  action: {
    margin: theme.spacing(0, 1),
  },
  inputsRow: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiFormControl-root': {
      margin: theme.spacing(0, 1),
    },
  },
  rightInputs: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function MessageFilterBar({
  filters,
  setFilters,
  companiesSectors,
  onAddClick,
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  const [companies, setCompanies] = useState([])

  const setSingleFilter = useCallback(
    (filterName, filterValue) => {
      setFilters((f) => ({ ...f, [filterName]: filterValue }))
    },
    [setFilters]
  )

  const onFiltersChange = (e) =>
    setSingleFilter(e.target.name, e.target.value.toString())

  const onArchivedCompaniesChange = (e) => {
    setSingleFilter(e.target.name, e.target.checked)
  }

  const selectAllHasChanged = (filters, valueArr) => {
    return (
      (filters.companyIds.includes('all') && !valueArr.includes('all')) ||
      (!filters.companyIds.includes('all') && valueArr.includes('all'))
    )
  }

  const onCompanyChange = (event) => {
    const { value } = event.target

    // On autofill we get a stringified value.
    const valueArr = typeof value === 'string' ? value.split(',') : value

    if (selectAllHasChanged(filters, valueArr)) {
      // Reset other checkboxes if the "all" checbox has changed
      setSingleFilter(
        'companyIds',
        valueArr.filter((companyId) => companyId === 'all')
      )
    } else {
      setSingleFilter(
        'companyIds',
        valueArr.filter((companyId) => companyId !== 'all')
      )
    }
  }

  useEffect(() => {
    const allCompaniesSectors = [
      ...(companiesSectors?.length > 1
        ? [
            {
              id: 'all',
              name: t('heatMap.filter.allCompanies'),
              archivedAt: null,
            },
          ]
        : []),
      ...companiesSectors,
    ]

    const filteredCompanies = allCompaniesSectors
      .filter((companySectors) => {
        return filters?.archived ? true : !companySectors?.archivedAt
      })
      .map((companySectors) => ({
        id: companySectors.id,
        name: companySectors.name,
        archivedAt: companySectors.archivedAt,
      }))
    setCompanies(filteredCompanies)

    if (companiesSectors.length === 1 && !filters?.companyIds?.length) {
      setSingleFilter('companyIds', [companiesSectors[0].id])
    }

    if (!filters?.archived && filters?.companyIds?.length) {
      const newCompanyIds = filters.companyIds.filter((companyId) =>
        filteredCompanies.find((company) => company.id === companyId)
      )
      if (newCompanyIds.length !== filters.companyIds.length) {
        setSingleFilter('companyIds', newCompanyIds)
      }
    }
  }, [
    filters?.archived,
    companiesSectors,
    filters?.companyIds,
    setSingleFilter,
    t,
  ])

  const controls = (
    <>
      <Box m={2} mb={1}>
        <ArchivedCompanyFilter
          archived={filters?.archived}
          onChange={onArchivedCompaniesChange}
        />
      </Box>

      <MultiCompanyFilter
        companies={companies}
        onChange={onCompanyChange}
        companyIds={filters.companyIds}
        classes={classes}
      />

      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.searchRoot}
      >
        <TextField
          // inputProps={{ 'data-testid': `cerca-${entity}-input` }}
          onChange={onFiltersChange}
          value={filters.search}
          label={t('common.textToSearch')}
          variant="outlined"
          name="search"
          size="small"
        />
      </FormControl>
    </>
  )
  return (
    <FilterBar entity={'message'} controls={controls} onAddClick={onAddClick} />
  )
}

MessageFilterBar.propTypes = {
  setFilters: T.func.isRequired,
  filters: T.shape({
    search: T.string,
    companyIds: T.array,
    archived: T.bool,
  }).isRequired,
  companiesSectors: T.array,
  onAddClick: T.func.isRequired,
}
