import React, { useEffect } from 'react'
import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import SectorTable from '../organisms/SectorTable'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

export default function Sectors() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId } = useParams()
  const history = useHistory()
  const {
    fetchedObject: company,
    isFetching,
    fetchError,
  } = useFetchCompany(companyId)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('sectorsPage.title')}</Typography>
      <Typography variant="h4">{company?.name}</Typography>
      <SectorTable companyId={companyId} />
      <Button
        onClick={() => history.goBack()}
        color="secondary"
        aria-label={t('common.back')}
      >
        {t('common.back')}
      </Button>
    </div>
  )
}
