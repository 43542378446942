import * as Yup from 'yup'

export const BackOfficeUserSchema = (t, isNew) => {
  const fieldRequired = t('common.fieldRequired')

  if (!isNew) {
    return Yup.object()
  }

  return Yup.object().shape({
    user: Yup.object().shape({
      id: isNew ? Yup.string().required(fieldRequired) : Yup.string(),
    }),
  })
}
