import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import UserForm from '../organisms/UserForm'

export default function UserDetail() {
  const history = useHistory()
  const { t } = useTranslation()
  const { id } = useParams()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('userDetailPage.detailTitle')}</Typography>
      <Button color="primary" onClick={() => history.push(`/user/${id}/rides`)}>
        {t('menu.rides')}
      </Button>
      <Button
        color="primary"
        onClick={() => history.push(`/user/${id}/rewards`)}
      >
        {t('menu.rewards')}
      </Button>
      <UserForm userId={id} />
    </div>
  )
}
