import React from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import ControlBox from './ControlBox'

const useStyles = makeStyles(() => ({
  themeLabel: {
    marginRight: '1rem',
  },
  saveButton: {
    marginRight: '1rem',
  },
}))

export default function HeatMapControlArea({
  theme,
  onThemeChange,
  ridesState,
  onRidesStateChange,
  showHeadquarters,
  onShowHeadquartersChange,
  onMapSave,
}) {
  const { t } = useTranslation()
  const classes = useStyles()

  return (
    <Box
      sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
    >
      <ControlBox>
        <Button
          color="primary"
          onClick={onMapSave}
          className={classes.saveButton}
        >
          {t('heatMap.downloadMapCanvas')}
        </Button>
      </ControlBox>

      <ControlBox>
        <FormLabel className={classes.themeLabel}>
          {t('heatMap.rides')}:
        </FormLabel>
        <RadioGroup
          row
          aria-label="rides"
          name="ridesState"
          value={ridesState}
          onChange={onRidesStateChange}
        >
          <FormControlLabel
            value="completed"
            control={<Radio />}
            label="Completati"
          />
          <FormControlLabel value="all" control={<Radio />} label="Tutti" />
        </RadioGroup>
      </ControlBox>

      <ControlBox>
        <FormControlLabel
          control={
            <Checkbox
              checked={showHeadquarters}
              onChange={onShowHeadquartersChange}
            />
          }
          label={t('heatMap.showHeadquarters')}
        />
      </ControlBox>

      <ControlBox>
        <FormLabel className={classes.themeLabel}>
          {t('heatMap.theme')}:
        </FormLabel>
        <RadioGroup
          row
          aria-label="theme"
          name="theme"
          value={theme}
          onChange={onThemeChange}
        >
          <FormControlLabel value="light" control={<Radio />} label="Chiaro" />
          <FormControlLabel value="dark" control={<Radio />} label="Scuro" />
        </RadioGroup>
      </ControlBox>
    </Box>
  )
}

HeatMapControlArea.propTypes = {
  theme: T.string.isRequired,
  onThemeChange: T.func.isRequired,
  ridesState: T.string.isRequired,
  onRidesStateChange: T.func.isRequired,
  showHeadquarters: T.bool.isRequired,
  onShowHeadquartersChange: T.func.isRequired,
  onMapSave: T.func.isRequired,
}
