import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useAuthEvent from './hooks/useAuthEvent'

const AuthListener = () => {
  const authEvent = useAuthEvent()
  const history = useHistory()

  useEffect(() => {
    if (authEvent.event === 'signOut') {
      history.push('/')
    }
  }, [authEvent, history])

  return null
}

export default AuthListener

AuthListener.propTypes = {}
