import * as Yup from 'yup'

export const ChallengeSchema = (t) => {
  const fieldRequired = t('common.fieldRequired')
  const rideTypeRequired = t('common.rideTypeRequired')

  return Yup.object().shape({
    name: Yup.string().required(fieldRequired),
    startDate: Yup.string().required(fieldRequired),
    endDate: Yup.string().required(fieldRequired),
    publicationDate: Yup.string().required(fieldRequired),
    rideTypeIds: Yup.array()
      .required(rideTypeRequired)
      .min(1, rideTypeRequired),
  })
}
