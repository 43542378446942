import { useCallback } from 'react'

import request from '../lib/request'
import useConfig from './useConfig'
import useFetch from './useFetch'

export default function useFetchBackOfficeUsersByRoles(companyId) {
  const { notAdminRoles } = useConfig()

  const fetchBackOfficeUsers = useCallback(async () => {
    if (!companyId) {
      return null
    }

    let result = {}
    for (const role of notAdminRoles) {
      result[role] = await request.get(
        `/admin/back-office-users?companyId=${companyId}&role=${role}`
      )
    }

    return result
  }, [companyId, notAdminRoles])

  return useFetch(fetchBackOfficeUsers)
}
