import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'
import CompanyForm from '../organisms/CompanyForm'

export default function CompanyDetail() {
  const history = useHistory()
  const { t } = useTranslation()
  const { isAdmin } = useAuth()
  const { id } = useParams()
  const isNew = !id

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew ? 'companyDetailPage.newTitle' : 'companyDetailPage.detailTitle'
        )}
      </Typography>
      {!isNew && (
        <>
          <Button
            color="primary"
            onClick={() => history.push(`/company/${id}/headquarters`)}
          >
            {t('menu.headquarters')}
          </Button>
          <Button
            color="primary"
            onClick={() => history.push(`/company/${id}/sectors`)}
          >
            {t('menu.sectors')}
          </Button>
          {isAdmin && (
            <Button
              color="primary"
              onClick={() => history.push(`/company/${id}/challenges`)}
            >
              {t('menu.challenges')}
            </Button>
          )}
          {isAdmin && (
            <>
              <Button
                color="primary"
                onClick={() => history.push(`/company/${id}/back-office-users`)}
              >
                {t('menu.backOfficeUsers')}
              </Button>
              <Button
                color="primary"
                onClick={() => history.push(`/company/${id}/history`)}
              >
                {t('menu.history')}
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  history.push(`/company/${id}/ride-match-analysis`)
                }
              >
                {t('menu.rideMatchAnalysis')}
              </Button>
              <Button
                color="primary"
                onClick={() =>
                  history.push(`/company/${id}/messaging-settings`)
                }
              >
                {t('menu.messagingSettings')}
              </Button>
              <Button
                color="primary"
                onClick={() => history.push(`/company/${id}/tech-detail`)}
              >
                {t('menu.companyTechDetail')}
              </Button>
            </>
          )}
        </>
      )}
      <CompanyForm companyId={id} />
    </div>
  )
}
