import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import formatISO from 'date-fns/formatISO'
import { Card } from '@mui/material'

import makeStyles from '@mui/styles/makeStyles'

import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'
import useCompaniesSectors from '../../../hooks/useCompaniesSectors'

import request from '../../../lib/request'
import useConfig from '../../../hooks/useConfig'
import useGoogleHeatMap from '../../../hooks/useGoogleHeatMap'
import useSnackbar from '../Snackbar/useSnackbar'
import { useAuth } from '../../../hooks/useAuth'

import HeatMapControlArea from './HeatMapControlArea'
import HeatMapSearchArea from './HeatMapSearchArea'

const GMAP_ID = 'gmap'

const darkGradient = [
  'rgba(0, 255, 255, 0)',
  'rgba(0, 255, 255, 1)',
  'rgba(0, 191, 255, 1)',
  'rgba(0, 127, 255, 1)',
  'rgba(0, 63, 255, 1)',
  'rgba(0, 0, 255, 1)',
  'rgba(0, 0, 223, 1)',
  'rgba(0, 0, 191, 1)',
  'rgba(0, 0, 159, 1)',
  'rgba(0, 0, 127, 1)',
  'rgba(63, 0, 91, 1)',
  'rgba(127, 0, 63, 1)',
  'rgba(191, 0, 31, 1)',
  'rgba(255, 0, 0, 1)',
]

const fetchRecords = async (filters) => {
  const { companyIds, startDate, endDate, archived } = filters
  const from = formatISO(startDate, { representation: 'date' })
  const to = formatISO(endDate, { representation: 'date' })
  return await request.get(
    `/admin/reports/heat-map?companyIds=${companyIds}&archived=${archived}&startDate=${from}&endDate=${to}`
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
  gmap: {
    // width: '700px',
    height: '500px',
    marginTop: '20px',
  },
  show: {
    display: 'block',
  },
  hide: {
    display: 'none',
  },
}))

export default function HeatMapTable() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const now = useMemo(() => new Date(), [])

  const { isAdmin } = useAuth()

  const companiesSectors = useCompaniesSectors()
  const [filters, setFilters] = useState({
    companyIds: [],
    startDate: new Date(now.getFullYear(), now.getMonth(), 1),
    endDate: now,
    archived: !isAdmin,
  })
  const [theme, setTheme] = useState('light')
  const [ridesState, setRidesState] = useState('completed')
  const [showHeadquarters, setShowHeadquarters] = useState(true)

  const [heatMapLocations, setHeatMapLocations] = useState([])
  const [markers, setMarkers] = useState([])
  const [fetchedMarkers, setFetchedMarkers] = useState([])

  const { gmapKey } = useConfig()
  const [mapElementId, setMapElementId] = useState(null)
  const [showNoRidesMessage, setShowNoRidesMessage] = useState(false)
  const { heatmap } = useGoogleHeatMap({
    heatMapLocations,
    markers,
    googleMapsKey: gmapKey,
    mapElementId,
    ridesState,
  })

  const memoedFetch = useCallback(
    async (filters) => {
      try {
        const result = await fetchRecords(filters)
        setHeatMapLocations(result.locations)
        setFetchedMarkers(
          result.headquarters.map((hq) => ({
            coords: hq.coords,
            title: `${hq.companyName} - ${hq.headquarterName}`,
            infoWindowContent: `<strong>${hq.companyName}</strong><br/>${hq.headquarterName}`,
          }))
        )
        setShowNoRidesMessage(!result.locations.length)
        if (result.headquarters.length) {
          setMapElementId(GMAP_ID)
        }
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
      }
    },
    [showSnackbarMessage, t]
  )

  useEffect(() => {
    if (!fetchedMarkers || !showHeadquarters) {
      setMarkers([])
      return
    }
    setMarkers(fetchedMarkers)
  }, [fetchedMarkers, showHeadquarters])

  const onThemeChange = (e) => {
    setTheme(e.target.value)
    heatmap.set('gradient', e.target.value === 'light' ? null : darkGradient)
  }

  const onMapSave = () => {
    const data = document.querySelector(`#${GMAP_ID} .gm-style>[tabindex="0"]`)
    const gmLayer = data.querySelector('.gm-style-moc')

    gmLayer.style.display = 'none'

    html2canvas(data, {
      useCORS: true,
      allowTaint: true,
    }).then(function (canvas) {
      gmLayer.style.display = null

      const blob = canvas.toDataURL('image/jpeg')
      saveAs(blob, `map.jpg`)
    })
  }

  const onRidesStateChange = (e) => {
    setRidesState(e.target.value)
  }

  const onShowHeadquartersChange = () => {
    setShowHeadquarters(!showHeadquarters)
  }

  return (
    <>
      <Card className={classes.root}>
        <HeatMapSearchArea
          searchHandler={memoedFetch}
          filters={filters}
          setFilters={setFilters}
          companiesSectors={companiesSectors}
        />
      </Card>
      {showNoRidesMessage && <div>{t('heatMap.noRidesMessage')}</div>}
      {heatMapLocations.length ? (
        <HeatMapControlArea
          theme={theme}
          onThemeChange={onThemeChange}
          ridesState={ridesState}
          onRidesStateChange={onRidesStateChange}
          showHeadquarters={showHeadquarters}
          onShowHeadquartersChange={onShowHeadquartersChange}
          onMapSave={onMapSave}
        />
      ) : null}
      <div
        id={GMAP_ID}
        className={`${classes.gmap} ${
          fetchedMarkers.length ? classes.show : classes.hide
        }`}
      />
    </>
  )
}
