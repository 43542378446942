import React from 'react'
import T from 'prop-types'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'
import request from '../../lib/request'

export default function AutocompleteTextField({
  label,
  name,
  partialUrl,
  getOptionLabel,
  getOptionSelected,
  value,
  onChange,
}) {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [options, setOptions] = React.useState([])
  const [inputValue, setInputValue] = React.useState('')

  const { t } = useTranslation()

  React.useEffect(() => {
    let active = true

    const fetchUsers = async () => {
      if (active && inputValue.length > 2) {
        setLoading(true)
        const data = await request.get(partialUrl + inputValue)
        if (active) {
          setLoading(false)
          setOptions(data)
        }
      }
    }
    fetchUsers()

    return () => {
      active = false
    }
  }, [partialUrl, inputValue])

  React.useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  return (
    <Autocomplete
      // id="asynchronous-demo"
      value={value}
      onChange={onChange}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      open={open}
      onOpen={() => {
        if (inputValue.length) {
          setOpen(true)
        }
      }}
      onClose={() => {
        setOpen(false)
      }}
      openOnFocus={false}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          name={name}
          variant="outlined"
          fullWidth
          margin="dense"
          size="small"
          helperText={t('common.autocompleteUserHelperText')}
          InputProps={{
            autoComplete: 'off',
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

AutocompleteTextField.propTypes = {
  label: T.string,
  partialUrl: T.string,
  getOptionLabel: T.func,
  getOptionSelected: T.func,
  name: T.string,
  value: T.object,
  onChange: T.func,
}
