import React, { useState, useRef, useCallback, useEffect } from 'react'
import T from 'prop-types'
import { Card } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import request from '../../../lib/request'
import Table from '../../Table'
import Action from '../../atoms/Action'
import useSnackbar from '../Snackbar/useSnackbar'
import TeamsFilterBar from './TeamsFilterBar'

const fetchTeams = async (query, filters, challengeId) => {
  const search = filters.search || ''
  const orderCollection = query.orderByCollection.find((c) => c.sortOrder === 1)
  const orderBy = orderCollection?.orderByField || ''
  const orderDirection = orderCollection?.orderDirection || ''
  const page = query.page ? query.page + 1 : ''
  const pageSize = query.pageSize || ''
  const { items: data, total: totalCount } = await request.get(
    `/admin/challenge-teams?challengeId=${challengeId}&search=${search}&orderBy=${orderBy}&orderDirection=${orderDirection}&page=${page}&pageSize=${pageSize}`
  )
  const offset = query.pageSize * query.page
  return {
    data,
    page: Math.ceil(offset / query.pageSize),
    totalCount,
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0),
    flex: 1,
  },
}))

export default function TeamsTable({ companyId, challengeId }) {
  const history = useHistory()
  const { isAdmin } = useAuth()

  const [filters, setFilters] = useState({ search: '' })
  const tableRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const classes = useStyles()

  const columns = [
    { title: t('teamsPage.tableHeaders.name'), field: 'name' }, // Assuming translation for "teamsPage.tableHeaders.name"
  ]

  useEffect(() => {
    tableRef.current.onQueryChange()
  }, [filters])

  const memoedFetch = useCallback(
    async (query) => {
      try {
        return await fetchTeams(query, filters, challengeId)
      } catch (e) {
        showSnackbarMessage({
          open: true,
          severity: 'error',
          message: `${t('common.unexpectedError')} ${e.message}`,
        })
        throw new Error('')
      }
    },
    [filters, challengeId, showSnackbarMessage, t]
  )

  const onAddClick = () => {
    return isAdmin
      ? () =>
          history.push(`/company/${companyId}/challenges/${challengeId}/team`) // Assuming team creation route
      : null
  }

  return (
    <Card className={classes.root}>
      <TeamsFilterBar
        filters={filters}
        setFilters={setFilters}
        onAddClick={onAddClick()}
      />
      <Table
        style={{ boxShadow: 'none' }}
        tableRef={tableRef}
        columns={columns}
        components={{
          Action,
        }}
        title=""
        data={memoedFetch}
        actions={[
          {
            icon: '',
            text: t('common.detail'),
            onClick: (e, rowData) => {
              history.push(
                `/company/${companyId}/challenges/${challengeId}/teams/${rowData.id}`
              ) // Assuming team detail route
            },
          },
        ]}
      />
    </Card>
  )
}

TeamsTable.propTypes = {
  challengeId: T.string.isRequired,
  companyId: T.string.isRequired,
}
