import React from 'react'
import T from 'prop-types'

import { Box, Checkbox, FormControlLabel } from '@mui/material'

import { useAuth } from '../../hooks/useAuth'

export default function CheckboxFilter({ name, label, checked, onChange }) {
  const { isAdmin } = useAuth()

  if (!isAdmin) {
    return null
  }

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            name={name}
            color="primary"
            checked={checked}
            onChange={onChange}
          />
        }
        label={label}
      />
    </Box>
  )
}

CheckboxFilter.propTypes = {
  name: T.string.isRequired,
  label: T.string.isRequired,
  checked: T.bool.isRequired,
  onChange: T.func.isRequired,
}
