import React, { useCallback } from 'react'
import T from 'prop-types'
import { useTranslation } from 'react-i18next'

import { Card, CardContent, Button, FormControl } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import AutocompleteTextField from '../../atoms/AutocompleteTextField'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 0,
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  action: {
    margin: theme.spacing(0, 1),
  },
  dates: {
    width: theme.spacing(22),
    marginLeft: theme.spacing(1),
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'left',
    margin: theme.spacing(1, 1),
    maxWidth: theme.spacing(80),
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
  },
}))

export default function SearchArea({
  searchHandler,
  filters,
  setFilters,
  companyId,
}) {
  const { t } = useTranslation()

  const classes = useStyles()

  const setSingleFilter = useCallback(
    (filterName, filterValue) => {
      setFilters((f) => ({ ...f, [filterName]: filterValue }))
    },
    [setFilters]
  )

  return (
    <Card className={classes.root}>
      <CardContent>
        <div>
          <FormControl
            variant="outlined"
            margin="dense"
            className={classes.dates}
          >
            <DatePicker
              margin="dense"
              label={t('rideMatchAnalysis.filter.date')}
              value={filters.date}
              onChange={(newDate) => {
                setSingleFilter('date', newDate)
              }}
              autoOk
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
            />
          </FormControl>

          <FormControl className={classes.users}>
            <AutocompleteTextField
              value={filters.driverUser}
              onChange={(event, newValue) => {
                setSingleFilter('driverUser', newValue)
              }}
              label={t('rideMatchAnalysis.filter.driverId')}
              partialUrl={`/admin/users/autocomplete?companyId=${companyId}&search=`}
              getOptionLabel={(option) =>
                option?.email
                  ? `${option.lastName} ${option.firstName} (${option.email})`
                  : ''
              }
              getOptionSelected={(option, value) =>
                option.email === value.email
              }
            />
          </FormControl>

          <FormControl className={classes.users}>
            <AutocompleteTextField
              value={filters.requesterUser}
              onChange={(event, newValue) => {
                setSingleFilter('requesterUser', newValue)
              }}
              label={t('rideMatchAnalysis.filter.requesterId')}
              partialUrl={`/admin/users/autocomplete?companyId=${companyId}&search=`}
              getOptionLabel={(option) =>
                option?.email
                  ? `${option.lastName} ${option.firstName} (${option.email})`
                  : ''
              }
              getOptionSelected={(option, value) =>
                option.email === value.email
              }
            />
          </FormControl>

          <div className={classes.buttons}>
            <Button
              color="primary"
              className={classes.action}
              disabled={
                !filters.date || !filters.driverUser || !filters.requesterUser
              }
              onClick={() => searchHandler(filters)}
            >
              {t('common.search')}
            </Button>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

SearchArea.propTypes = {
  searchHandler: T.func.isRequired,
  filters: T.object.isRequired,
  setFilters: T.func.isRequired,
  companyId: T.string.isRequired,
}
