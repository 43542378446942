import React, { useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { styled } from '@mui/material/styles'

import { Toolbar, Typography, Stack, Link, Button } from '@mui/material'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { format, parseISO } from 'date-fns'
import makeStyles from '@mui/styles/makeStyles'

import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import useFetch from '../../hooks/useFetch'
import request from '../../lib/request'
import BasicTable from '../organisms/BasicTable/BasicTable'

//TODO avoid full page redirect
function TravellerName(user) {
  return (
    <Link
      key={user.id}
      href={`/user/${user.id}`}
      sx={{
        color: '#1976d2!important',
        '&.a:visited': { color: '#1976d2!important' },
      }}
      underline="hover"
    >
      {user.firstName} {user.lastName}
    </Link>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))

export default function RideDetail() {
  const { rideId } = useParams()
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }))

  const getTravellerName = function (travellerId) {
    const traveller = ride.travellers.find((tr) => tr.id === travellerId)
    if (traveller) {
      return TravellerName(traveller.user)
    }

    return 'NOT_FOUND'
  }

  const fetchRide = useCallback(async () => {
    if (!rideId) {
      return null
    }
    return request.get(`/admin/rides/${rideId}`)
  }, [rideId])

  const { fetchedObject: ride, isFetching } = useFetch(fetchRide)

  return (
    <>
      <div className="content-container">
        <Toolbar />
        <Typography variant="h3">{t('rideDetailPage.title')}</Typography>
        {!isFetching && (
          <>
            <Grid className={classes.root} container spacing={2}>
              <Grid item xs={7}>
                <Item>
                  <Typography variant="h6" color="black">
                    Dettaglio
                  </Typography>
                  <Stack fontSize={16} color="black">
                    <span>
                      Data viaggio:{' '}
                      {format(parseISO(ride.rideDate), 'dd/MM/yyyy')}
                    </span>
                    <span>Tipologia: {ride.rideTypeId}</span>
                    <span>Da: {ride.actualRoute.origin.address}</span>
                    <span>A: {ride.actualRoute.destination.address}</span>
                  </Stack>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <Typography variant="h6" color="black">
                    Viaggiatori
                  </Typography>
                  <Stack fontSize={16} color="black">
                    {ride.travellers.map((traveller) => (
                      <span>
                        {TravellerName(traveller.user)} ({traveller.state})
                      </span>
                    ))}
                  </Stack>
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="h6">
                    {t('rideDetailPage.rewards')}
                  </Typography>
                  <BasicTable
                    rows={ride.rewards}
                    rowHead={
                      <TableRow>
                        <TableCell align="left">
                          {t('rideDetailPage.user')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.km')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.cost')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.credits_assigned')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.passengers')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.savedCO2')}
                        </TableCell>
                        <TableCell align="left">
                          {t('rideDetailPage.date')}
                        </TableCell>
                      </TableRow>
                    }
                    rowSlot={(row, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {getTravellerName(row.travellerId)}
                          </TableCell>
                          <TableCell align="left">
                            {Intl.NumberFormat(i18n.language, {
                              style: 'unit',
                              unit: 'kilometer',
                              maximumFractionDigits: 2,
                            }).format(row.actualKm || 0)}
                          </TableCell>
                          <TableCell align="left">
                            {Intl.NumberFormat(i18n.language, {
                              style: 'currency',
                              currency: 'EUR',
                              maximumFractionDigits: 2,
                            }).format(row.carRideKmCost || 0)}
                          </TableCell>
                          <TableCell align="left">{row.credits || 0}</TableCell>
                          <TableCell align="left">
                            {row.passengers || 0}
                          </TableCell>
                          <TableCell align="left">
                            {Intl.NumberFormat(i18n.language, {
                              style: 'unit',
                              unit: 'kilogram',
                              maximumFractionDigits: 2,
                            }).format(row.savedCo2 || 0)}
                          </TableCell>
                          <TableCell align="left">
                            {format(
                              parseISO(row.assignmentDate),
                              'dd/MM/yyyy hh:mm'
                            )}
                          </TableCell>
                        </TableRow>
                      )
                    }}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <Typography variant="h6">Stati</Typography>
                  <BasicTable
                    rows={ride.travellerStates}
                    rowHead={
                      <TableRow>
                        <TableCell align="left">Viaggiatore</TableCell>
                        <TableCell align="left">Stato</TableCell>
                        <TableCell align="left">Data</TableCell>
                      </TableRow>
                    }
                    rowSlot={(row) => {
                      return (
                        <TableRow
                          key={row.date}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {getTravellerName(row.travellerId)}
                          </TableCell>
                          <TableCell align="left">{row.state}</TableCell>
                          <TableCell align="left">
                            {format(parseISO(row.date), 'dd/MM/yyyy hh:mm')}
                          </TableCell>
                        </TableRow>
                      )
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Button
              onClick={() => history.goBack()}
              color="secondary"
              aria-label={t('common.back')}
            >
              {t('common.back')}
            </Button>
          </>
        )}
      </div>
    </>
  )
}
