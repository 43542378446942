import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import useFetchCompany from '../../hooks/useFetchCompany'
import Spinner from '../atoms/Spinner'
import SectorForm from '../organisms/SectorForm'
import useSnackbar from '../organisms/Snackbar/useSnackbar'

export default function SectorDetail() {
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId, id } = useParams()
  const { fetchedObject: company, fetchError } = useFetchCompany(companyId)

  const isNew = !id

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">
        {t(
          isNew ? 'sectorDetailPage.newTitle' : 'sectorDetailPage.detailTitle'
        )}
      </Typography>
      {company ? (
        <>
          <Typography variant="h4">{company?.name}</Typography>
          <SectorForm companyId={companyId} sectorId={id} />
        </>
      ) : (
        <Spinner show={true} />
      )}
    </div>
  )
}
