import React from 'react'
import { Auth } from 'aws-amplify'
import { Button, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../hooks/useAuth'

export default function UnauthorizedPage() {
  const { companyArchivedAt } = useAuth()
  const { t } = useTranslation()

  const translationBodyKey = companyArchivedAt
    ? 'unauthorizedPage.archivedCompanyBody'
    : 'unauthorizedPage.body'

  const logoutHandler = () => {
    Auth.signOut({ global: true })
  }

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('unauthorizedPage.title')}</Typography>

      <Typography>{t(translationBodyKey)}</Typography>

      <Button color="primary" onClick={logoutHandler}>
        Logout
      </Button>
    </div>
  )
}
