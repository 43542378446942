import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchMessage(messageId) {
  const fetchCompany = useCallback(async () => {
    if (!messageId) {
      return null
    }
    return request.get(`/admin/messages/${messageId}`)
  }, [messageId])

  return useFetch(fetchCompany)
}
