const reloadImageOnError = () => {
  let reloadAttemps = 6
  return (e) => {
    if (reloadAttemps-- > 0) {
      setTimeout(() => {
        e.target.src = e.target.src + '#' + Math.random()
      }, 500)
    }
  }
}

module.exports = {
  reloadImageOnError,
}
