import React from 'react'
import T from 'prop-types'
import FilterBar from '../FilterBar/FilterBar'

export default function BackOfficeUsersFilterBar({ onAddClick, entity }) {
  return <FilterBar entity={entity} onAddClick={onAddClick} />
}

BackOfficeUsersFilterBar.propTypes = {
  onAddClick: T.func.isRequired,
  entity: T.string.isRequired,
}
