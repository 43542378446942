import React from 'react'
import { Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MessageTable from '../organisms/MessageTable'

export default function Messages() {
  const { t } = useTranslation()

  return (
    <div className="content-container">
      <Toolbar />
      <Typography variant="h3">{t('messagesPage.title')}</Typography>
      <MessageTable />
    </div>
  )
}
