import React from 'react'
import T from 'prop-types'
import {
  PieChart as CPieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts'

export default function PieChart({
  data,
  dataKey,
  colorKey,
  colors,
  percent,
  ...others
}) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <CPieChart>
        <Legend align="left" />
        <Pie
          data={data || []}
          outerRadius="70%"
          label={(entry) => {
            return percent ? `${(entry.percent * 100).toFixed()}%` : entry.value
          }}
          dataKey={dataKey}
          {...others}
        >
          {data.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={
                data[index]?.[colorKey] ||
                (colors && colors?.[index % colors?.length]) ||
                '#8884d8'
              }
            />
          ))}
        </Pie>
        <Tooltip />
      </CPieChart>
    </ResponsiveContainer>
  )
}

PieChart.propTypes = {
  data: T.array,
  dataKey: T.string.isRequired,
  colorKey: T.string,
  colors: T.array,
  percent: T.bool,
}
