import T from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Formik, Form } from 'formik'

import { Box, Button, Divider, Paper, Typography } from '@mui/material'

import request from '../../../lib/request'
import Checkbox from '../../atoms/Checkbox'

import Spinner from '../../atoms/Spinner'
import TextField from '../../atoms/TextField'
import BackOfficeUsersTable from '../BackOfficeUsersTable'

import useSnackbar from '../Snackbar/useSnackbar'
import useFetchCompanyRole from '../../../hooks/useFetchCompanyRole'
import { CompanyRoleSchema } from './validators'

export default function BackOfficeUsersTab({
  company,
  role,
  backOfficeUsersByRoles,
}) {
  const formikRef = useRef()
  const { showSnackbarMessage } = useSnackbar()
  const { t } = useTranslation()
  const { companyId } = useParams()

  const pageModeViewState = { options: 'view', reportSubscribers: 'view' }
  const [pageMode, setPageMode] = useState(pageModeViewState)
  const optionsReadOnly = pageMode.options === 'view'
  const reportSubscribersReadOnly = pageMode.reportSubscribers === 'view'

  const {
    fetchedObject: companyRole,
    isFetching,
    fetchError,
  } = useFetchCompanyRole(companyId, role)

  useEffect(() => {
    if (fetchError) {
      showSnackbarMessage({
        open: true,
        severity: 'error',
        message: `${t('common.unexpectedError')} ${fetchError}`,
      })
    }
  }, [fetchError, showSnackbarMessage, t])

  async function handleSubmit(values, actions) {
    try {
      const data = {
        companyId,
        role,
        anonymousReports: values.anonymousReports,
        reportSubscribersCustom: values.reportSubscribersCustom,
        reportSubscribersAsBackOfficeUsers:
          companyRole.reportSubscribersAsBackOfficeUsers,
      }

      await request.post(`/admin/back-office-users/save-company-role`, data)
      actions.setSubmitting(false)
      setPageMode(pageModeViewState)

      showSnackbarMessage({ open: true, severity: 'success' })
    } catch (e) {
      let message, autoHideDuration
      switch (e.response.data.errorCode) {
        case 'valid_domains_collision':
          message =
            t('entities.company.collidingDomains') +
            e.response.data.collidingCompanies
              .map((company) => company.name)
              .join(', ')
          autoHideDuration = 15000
          break
        case 'valid_domains_required':
          message = t('entities.company.domainsRequired')
          break
        default:
          message = e.response.data?.message || e.message
      }

      showSnackbarMessage({
        open: true,
        severity: 'error',
        autoHideDuration,
        message,
      })
      actions.setSubmitting(false)
    }
  }

  function handleEditOptions(e) {
    e.preventDefault()
    setPageMode((state) => ({
      ...state,
      options: 'edit',
    }))
  }

  function handleEditReportSubscribers(e) {
    e.preventDefault()
    setPageMode((state) => ({
      ...state,
      reportSubscribers: 'edit',
    }))
  }

  function handleReset() {
    setPageMode(pageModeViewState)
  }

  return isFetching ? (
    <Spinner show={true} />
  ) : (
    <Formik
      innerRef={formikRef}
      validationSchema={CompanyRoleSchema(t)}
      initialValues={{
        anonymousReports: companyRole?.anonymousReports || false,
        reportSubscribersCustom: companyRole?.reportSubscribersCustom || '',
      }}
      onSubmit={handleSubmit}
      onReset={handleReset}
      enableReinitialize={true}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form>
          <Paper>
            <Box p={3}>
              <Typography variant="h6">
                {t('backOfficeUsersPage.options')}:
              </Typography>
              <Box>
                <Checkbox
                  inputProps={{
                    disabled: optionsReadOnly,
                  }}
                  color="primary"
                  label={t('entities.company.anonymousReports')}
                  name="anonymousReports"
                />
              </Box>
              {optionsReadOnly ? (
                <>
                  <Button
                    onClick={handleEditOptions}
                    color="primary"
                    aria-label={t('common.edit')}
                  >
                    {t('common.edit')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="reset"
                    color="secondary"
                    aria-label={t('common.cancel')}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
              <Divider
                style={{
                  margin: '10px 0 20px',
                  height: '3px',
                  backgroundColor: 'rgba(0, 0, 0, 0.40)',
                }}
              />

              <Typography variant="h6">
                {t('backOfficeUsersPage.reportSubscribers')}:
              </Typography>
              <Box>
                <TextField
                  style={{ width: '80%' }}
                  margin="dense"
                  inputProps={{
                    autoComplete: 'off',
                    readOnly: reportSubscribersReadOnly,
                  }}
                  label={t('entities.company.reportSubscribers')}
                  name="reportSubscribersCustom"
                  size="small"
                />
                {companyRole.reportSubscribersAsBackOfficeUsers && (
                  <Box>
                    {t('backOfficeUsersPage.subscriberNote')}{' '}
                    <strong>
                      {companyRole.reportSubscribersAsBackOfficeUsers}
                    </strong>
                  </Box>
                )}
              </Box>

              {reportSubscribersReadOnly ? (
                <>
                  <Button
                    onClick={handleEditReportSubscribers}
                    color="primary"
                    aria-label={t('common.edit')}
                  >
                    {t('common.edit')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="reset"
                    color="secondary"
                    aria-label={t('common.cancel')}
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    disabled={!isValid || !dirty || isSubmitting}
                    color="primary"
                  >
                    {t('common.save')}
                  </Button>
                </>
              )}
              <Divider
                style={{
                  margin: '10px 0 20px',
                  height: '3px',
                  backgroundColor: 'rgba(0, 0, 0, 0.40)',
                }}
              />

              <Typography variant="h6">
                {t('backOfficeUsersPage.usersList')}:
              </Typography>
              <BackOfficeUsersTable
                company={company}
                role={role}
                backOfficeUsersByRoles={backOfficeUsersByRoles}
              />
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}

BackOfficeUsersTab.propTypes = {
  company: T.object.isRequired,
  role: T.string.isRequired,
  backOfficeUsersByRoles: T.object.isRequired,
}
