import React from 'react'
import T from 'prop-types'

import { useTranslation } from 'react-i18next'

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}
export default function MultiCompanyFilter({
  companyIds,
  onChange,
  companies,
  showSelected,
  readOnly,
  companiesById,
  classes,
}) {
  const { t } = useTranslation()

  return (
    <div className={classes.multiCompanyRoot}>
      <FormControl
        variant="outlined"
        margin="dense"
        size="small"
        className={classes.multiCompanyFormControl}
      >
        <InputLabel id="companyId-label">
          {t('heatMap.filter.company')}
        </InputLabel>
        <Select
          label={t('heatMap.filter.company')}
          name="companyId"
          multiple
          value={companyIds}
          renderValue={() => t('common.select')}
          disabled={!!readOnly}
          onChange={onChange}
          MenuProps={MenuProps}
          size="small"
        >
          {companies.map((c) => (
            <MenuItem key={c.id} value={c.id}>
              <Checkbox checked={companyIds.indexOf(c.id) > -1} />
              <ListItemText primary={c.name} />
            </MenuItem>
          ))}
        </Select>

        {showSelected && companyIds.length ? (
          <div className={classes.multiCompanySelectedBox}>
            Aziende selezionate:
            <ul>
              {companyIds.map((id) => (
                <li key={id}>{companiesById[id]}</li>
              ))}
            </ul>
          </div>
        ) : null}
      </FormControl>
    </div>
  )
}

MultiCompanyFilter.propTypes = {
  companyIds: T.arrayOf(T.string).isRequired,
  onChange: T.func.isRequired,
  companies: T.array.isRequired,
  showSelected: T.bool,
  readOnly: T.bool,
  companiesById: T.object,
  classes: T.object,
}
