import React from 'react'
import T from 'prop-types'
import { FormControl, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import FilterBar from '../FilterBar/FilterBar'

const useStyles = makeStyles((theme) => ({
  // ... (styles remain the same)
}))

export default function TeamsFilterBar({ filters, setFilters, onAddClick }) {
  const { t } = useTranslation()
  const classes = useStyles()

  const onFiltersChange = (e) =>
    setFilters((f) => ({ ...f, [e.target.name]: e.target.value.toString() }))

  const controls = (
    <>
      <FormControl
        variant="outlined"
        margin="dense"
        className={classes.selectRoot}
      >
        <TextField
          onChange={onFiltersChange}
          value={filters.search}
          label={t('teamsPage.textToSearch')} // Assuming translation key for teams search
          variant="outlined"
          name="search"
          size="small"
        />
      </FormControl>
    </>
  )

  return (
    <FilterBar entity={'team'} controls={controls} onAddClick={onAddClick} />
  )
}

TeamsFilterBar.propTypes = {
  setFilters: T.func.isRequired,
  filters: T.shape({
    search: T.string,
  }).isRequired,
  onAddClick: T.func.isRequired,
}
