import { useCallback } from 'react'
import request from '../lib/request'
import useFetch from './useFetch'

export default function useFetchCompanyRole(companyId, role) {
  const fetchCompanyRole = useCallback(async () => {
    if (!companyId || !role) {
      return null
    }
    return request.get(
      `/admin/back-office-users/get-company-role?companyId=${companyId}&role=${role}`
    )
  }, [companyId, role])

  return useFetch(fetchCompanyRole)
}
